.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.image{
  /* height: 250px !important; */
  width: 100% !important;
  object-fit:cover;
}
.sellimage
{
  height: 200px !important;
  /* width: 80% !important; */
  object-fit:cover;
}
.nftimage
{
  border-radius: 20.8798px;
  /* height: 200px !important; */
  width: 100% !important;
  object-fit:contain;
}

.search_image{
  height: 250px !important;
  width: 100% !important;
  object-fit:cover;
}

.images
{
  height: 230px;
  width:400px;
  object-fit:cover;
}
.icon{
  
  border: 5px solid white;
  width: 150px;
  height: 150px;
  border-radius: 100px;
  object-fit:contain;
  background:#f2f2f2;
}

.banner{
  width: 100%;
  height: 300px;
  height: clamp(24vw,24vw,300px);
  background-repeat: no-repeat;
  background-size: contain;
  object-fit:cover;
  object-position: top center;
}
.banners
{
  width: 100%;
  height: 300px;
  object-fit:cover;
}
.bannerss
{
  width: 250px;
  height: 250px;
  object-fit:cover;
}


.blur-image {
  filter: blur(5px);
  -webkit-filter: blur(5px);
} 
.buy
{
  width:120px;
  height:100px;
  object-fit:cover;
}
/* .buys
{
  width:100%;
  height:222px;
  object-fit:cover;
} */
.bord
{
  width: 140px !important;
  height: 140px;
  border-radius: 100px;
  position: absolute;
  /* margin-left : 1px; */
}
.pointer
{
  cursor:pointer
}
html {
  scroll-behavior: smooth !important;
}

.scroll_filter{
  position: -webkit-sticky;
    position: sticky;
    top: 24px;
    bottom: 0;
    z-index: 2;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

/* .search-input-box {
  width: 260px !important;
} */

.search-input-box input{
 
    width:100%;
    border-radius:20px !important;
    border: 2px solid #0000004f !important;
    padding-left: 30px;
    border-radius: 10%;
}

.search-input-box div{
 background:#fff !important;
 z-index:999;
 margin-top: 5px;
 }

.btn.btnSrh_v2 {
  position: relative;
  left: -2.5rem;
}
.img{
  max-width: 300px !important;
  object-fit:cover;
  object-position: top center;
}
.header-rank
{
  width:100%;
  height:100px;
  object-fit: cover;
}
.imgfluid
{
  max-width: 100%;
    height: auto;
    border-radius: 50%;
}
.prof
{
  width:200px;
  height: 140px;
  object-fit: cover;
}
.imgs {

  width: 550px ;
  height: 250px ;
  object-fit: cover;
}
.sticky {
  position: sticky;
  top: 0;
  /* color: black; */
  background-image: linear-gradient(91.18deg, #FCF4FF 3.78%, #FCF4FF 3.79%, #F4F2FF 27.49%, #E6F9FF 59.57%, #F7FFF1 89.33%);
}
.wrapper {
  overflow-y: scroll;
  max-height: 764px !important;
}
.wrapper1 {
  overflow-y: scroll;
  max-height: 330px !important;
}
.wrapper2 {
  overflow-y: scroll;
  max-height: 10px !important;
}


.wrapper3 {
  overflow-y: scroll;
  max-height: 1367px !important;
}