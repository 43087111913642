/*Supporter-2 Style*/
/*Admin login page css*/
.LinrGrdBg {
  background: linear-gradient(55.67deg, #FCF4FF 28.17%, #FCF4FF 28.17%, #F4F2FF 44.85%, #E6F9FF 67.43%, #F7FFF1 88.37%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  min-height: 100vh;
}
.AdnmLgnFrm{
  display:flex;
  justify-content:center;
  align-content:center;
  flex-direction:column;
  min-height:100%;
}
.AdmnLogoImg {
    margin-bottom: 4.8rem;
}
.BtnH52W298{
  min-width:298px;
  min-height:52px;
}
.LognMt{
  margin-top:4.8rem;
}
/*Admin login Form css*/
  .Logn-frm .form-control{
    background: rgba(251, 251, 251, 0.85);
    border: 1px solid #474747;
    border-radius: 5px;
    min-height:55px;
    box-shadow:unset;
    outline: none;
  }
  .Logn-frm .FrmLbl{
    font-size: 18px;
    font-family:var(--font-optima-bold);
    color:#000000;
    font-weight: bold;
  }
  .Btngreen{
    background: #474747;
    box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5), 5px 5px 8px rgba(226, 226, 226, 0.25), 10px 10px 20px rgba(223, 223, 223, 0.5), -10px -10px 20px #FFFFFF;
    border-radius: 32px;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 20px;
    border: 1px solid #474747;
  }
/*Admin login Form css end*/
/*Admin login page css end*/




/*Admin Header css end*/
.AdmnHddrr{
  background: linear-gradient(280deg, #FCF4FF 3.78%, #FCF4FF 3.79%, #F4F2FF 27.49%, #E6F9FF 59.57%, #F7FFF1 89.33%);
}
.AdmnHddrr .AdmnLnks.navbar-nav .nav-link{
  font-weight: bold;
  font-size: 18px;
  color: #000000;
  font-family:var(--font-optima-bold);
  margin-left:2rem;
}
.AdmnHddrr .AdmnLnks.navbar-nav .nav-link.Btngreen{
  color:#fff;
  padding:3px 10px;
}

@media (max-width:991px){
  /*animation collapse*/
  .AdmnHddrr .navbar-toggler .icon-bar {
    width: 25px;
    display: block;
    height: 4px;
    background-color: #1d1d1b;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    border-radius: 3px;
  }
  .AdmnHddrr .navbar-toggler .icon-bar + .icon-bar {
    margin-top: 4px;
  }
  .AdmnHddrr .navbar-toggler .top-bar {
    transform: translateZ(5px) rotate(135deg);
    transform-origin: 35% 150%;
  }
  .AdmnHddrr .navbar-toggler .mid-bar {
    opacity: 0;
  }
  .AdmnHddrr .navbar-toggler .bot-bar {
    transform: translateZ(5px) rotate(-135deg);
    transform-origin: 40% -10%;
  }
  .AdmnHddrr .navbar-toggler.collapsed .top-bar {
    transform: rotate(0);
  }
  .AdmnHddrr .navbar-toggler.collapsed .mid-bar {
    opacity: 1;
  }
  .AdmnHddrr .navbar-toggler.collapsed .bot-bar {
    transform: rotate(0);
  }
}
  /*animation collapse style end*/
/*Admin Header css end*/

/*Company details page css*/
.AdmTitle h4{
  color: #000000;
  font-weight: bold;
  font-size: 20px;
}
.Admin-main{
  padding-top:9rem;
}
.AdmBgR10f2f2{
  background:#f2f2f24d;
  border-radius: 10px;
  min-height:900px;
}
.AdmComp-Frm .input-group-text{
  border: 1px solid #474747;
  border-left:unset;
  background:rgba(251, 251, 251, 0.85);
  cursor:pointer;
  font-size:17px;
  font-weight:bold;
  color:#000;
}
.AdmComp-Frm .form-control{
  background:#fbfbfb;
  border: 1px solid #474747;
  border-radius: 5px;
  min-height:40px;
  box-shadow:unset;
  outline: none;
  font-size:14px;
  color: #000000;
}
.AdmComp-Frm .input-group .form-control{
  border-right:unset;
}
.AdmComp-Frm .FrmLbl{
  font-size: 18px;
  font-family:var(--font-optima-bold);
  color:#000000;
  font-weight: bold;
}
.CmpnyDetBx{
  padding:40px 40px;
}
/*Company details page End css*/

/*Admin metamask page css*/
.AdmMetaImg{
  background: #DFDFDF;
  border-radius:18px;
  padding:20px;
  width:160px;
  margin: 0 auto;
}
.AdmMeta h4{
  font-weight:bold;
  font-size: 18px;
  color: #474747;
}
.BtnH46R5{
  min-height:46px;
  border-radius:5px;
  width:100%;
}
/*Admin metamask page End css*/

/*Admin NFT list page css*/
.BtnW168H46{
min-width:168px;
min-height:46px;
border-radius:5px;
box-shadow:unset;
}
.AdmSrch .form-control{
background: #FFFFFF;
border: 1px solid #9F9F9F;
border-radius: 30px;
font-size: 20px;
color: #474747;
border-right:unset;
min-height:50px;
box-shadow:unset;
outline:unset;
}
.AdmSrch .input-group-text{
background:#fff;
border: 1px solid #9F9F9F;
border-radius:0px 30px 30px 0px;
border-left:unset;
min-height:50px;
cursor:pointer;
}
.NftList .table td{
border-top:1px solid #474747;
}
.NftList .table thead th{
border-bottom: 2px solid #474747;
border-top:unset;
font-weight: bold;
font-size: 20px;
color: #000000;
font-family:var(--font-optima-bold);
}
/*Admin NFT list page css end*/

/*Admin Create collection1 page css*/

.AdmComp-Frm select.form-control{
  color:#7c7c7c;
  background:url('/assets/images/adm-slect-arrow.png') no-repeat right 10px center!important;
}
.Tx18Bff1{
  color:#FF1D1D;
}
.ClsBtn{
  background: rgba(230, 230, 230, 0.85);
  border: 1px solid #474747;
  border-radius: 5px;
  padding:10px;
}
.AdmChsSec{
  background: rgba(251, 251, 251, 0.85);
  border: 1px solid #474747;
  border-radius: 5px;
  padding:25px;
}
.AdmChsSec [type=file] {
  height: 0;
  overflow: hidden;
  width: 0;
  position: absolute;
}
.AdmChsSec [type=file] + label {
  background: #474747;
  color: #fff;
  border: 1px solid #474747;
  font-weight: 600;
  font-size: 15px;
  width: 135px;
  margin: auto;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor:pointer;
}
a.Tx18R000{
  font-size: 18px;
  color: #000000;
  text-decoration:none;
}
.Tx16R000{ 
  font-size: 16px;
  color: #000000;
}
.BtnW298H52{
  min-width:298px;
  min-height:52px;
  background: #474747;
  border-radius: 32px;
}
.AdmComp-Frm .form-control.Bgf6f6{
  background:#f6f6f6;
}
.AdmComp-Frm select.form-control.Bgf6f6{
  background:#f6f6f6 url('/assets/images/adm-slect-arrow.png') no-repeat right 10px center!important;
}
/*Admin Create collection1 page css end*/

/*Admin Create collection2 page css end*/
.Bg6767{
  background:#676767;
}
.Bg8f8f{
  background:#8f8f8f;
}
.Tx18R000{ 
  font-size: 18px;
  color: #000000;
}


.EdtUpldSec{
  position:relative;
  padding:0;
}
.EdtUpld{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
}
/*FAQ section css*/

.faq-box .card { 
  cursor: pointer;
  padding: 0;
  margin-bottom: 15px;
  border: 1px solid #474747;
  box-sizing: border-box;
  border-radius: 5px;
}
.faq-box .card-header { 
  background:transparent;
  border-color:transparent;
  padding:0px 0px;
  position:relative;
}
.faq-box .card-header h5 { 
  font-weight: bold;
  font-size: 18px;
  padding: 17px 25px 13px;
  margin: 0;
  color: #000000; 
}
.faq-box .card-header h5 i.fa { 
  margin-right:17px;
}
.faq-box .card-body { 
  padding: 30px 20px 30px 20px;
}
.faq-box .card-body p{
  font-size: 18px;
  line-height: 24px;
  color: #000;
}
.faq-box [data-toggle="collapse"] .fa:before,.faq-box [aria-expanded="true"] .fa:before {  
  content: "\f107";
  font-weight: 500;
  font-size: 25px;
  transition: .5s all ease-in-out;
  display: inline-block;
  color: #000;
  margin-right:20px; 
  transform: rotate(-180deg);
}
.faq-box [data-toggle="collapse"].collapsed .fa:before,.faq-box [aria-expanded="false"] .fa:before {  
  content: "\f107";
  transform: rotate(0deg);
  font-weight: 500;
  font-size: 25px;
  display: inline-block;
  color: #000;
  margin-right:20px;
  transition: .5s all ease-in-out;
} 
.faq-box .card-header[data-toggle="collapse"].collapsed{
  border: 1px solid transparent;
  background:transparent;
  border-radius: 10px 10px 0px 0px;
  transition:.4s all ease-in-out;
  border-radius:10px;
}
.faq-box [data-toggle="collapse"]{
  border-radius: 5px;
  background: #FBFBFB;
  border-bottom: 1px solid #474747;
}
.faq-box .card-header:hover .fa:before{
  color:#000;
}
.d-fw-bt {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AdmComp-Frm .form-control.Read-Only,
.AdmComp-Frm .form-control:disabled,
.AdmComp-Frm .form-control[readonly]{
  background: #EAEAEA;
  border-radius: 5px;
  border: 1px solid #eaeaea;
}
.AdmComp-Frm select.form-control.SlctRdOnly{
  background: #F7F7F7;
}


.AdmColLst{
  background: #FFFFFF;
  box-shadow: 0px 2px 3px #C4C4C4;
  border-radius: 25px;
  padding:15px;
  min-height:360px;
}
.CllstCnt h4{
  font-weight: bold;
  font-size: 20px;
  color: #000000;

}

.accordion__button{
  background:transparent;
}

