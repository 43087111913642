@import url('https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css');
@import url('https://pro.fontawesome.com/releases/v5.13.0/css/all.css');
/*--||
Common Use Start
||--*/
*
{
    box-sizing: border-box;
}

:root
{
  --font-optima-regular: 'OPTIMA';
  --font-optima-medium: 'Optima-Medium';
  --font-optima-bold: 'OPTIMA_BOLD';
  --font-roboto: 'Roboto', sans-serif;
}

img 
{
    max-width: 100%;
}

body
{
    font-family: var(--font-optima-regular);    
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

body *
{
    font-family: Arial, Helvetica, sans-serif !important;
}

@font-face {
  font-family: 'OPTIMA';
  src: url('../assets/fonts/OPTIMA.eot') format('EOT'),
    url('../assets/fonts/OPTIMA.otf') format('OpenType'),
    url('../assets/fonts/OPTIMA.svg') format('svg'),
    url('../assets/fonts/OPTIMA.TTF') format('TrueType'),
    url('../assets/fonts/OPTIMA.woff') format('woff'),
    url('../assets/fonts/OPTIMA.woff2') format('woff2');
  font-style: normal;  
  font-display: swap;  
}


@font-face {
  font-family: 'OPTIMA_BOLD';
  src: url('../assets/fonts/OPTIMA_BOLD.eot') format('EOT'),
    url('../assets/fonts/OPTIMA_BOLD.otf') format('OpenType'),
    url('../assets/fonts/OPTIMA_BOLD.svg') format('svg'),
    url('../assets/fonts/OPTIMA_BOLD.TTF') format('TrueType'),
    url('../assets/fonts/OPTIMA_BOLD.woff') format('woff'),
    url('../assets/fonts/OPTIMA_BOLD.woff2') format('woff2');
  font-style: normal;  
  font-display: swap;  
}

@font-face {
  font-family: 'Optima-Medium';
  src: url('../assets/fonts/Optima-Medium.eot') format('EOT'),
    url('../assets/fonts/Optima-Medium.otf') format('OpenType'),
    url('../assets/fonts/Optima-Medium.svg') format('svg'),
    url('../assets/fonts/Optima-Medium.ttf') format('TrueType'),
    url('../assets/fonts/Optima-Medium.woff') format('woff'),
    url('../assets/fonts/Optima-Medium.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
}



@media only screen and (min-width: 1200px)
{
    .container-1260 {
        max-width: 1290px;
    }


    .container-1340
    {
        max-width: 1370px;
    }
}
/*--||
Common Use End
||--*/



/*--||
Page Loader Content Start
||--*/
.pgLoad {
    position: fixed;
    top: 0px;
    left: 0px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: 999;
}
/*--||
Page Loader Content End
||--*/




/*--||
Headeer Content Start
||--*/
.indHd {
    padding: 12px 0px;
}


@media only screen and (min-width: 768px)
{
    .logoAr .logo-sm 
    {
        display: none;
    }
}
@media only screen and (max-width: 767px)
{
    // .logoAr .logo-pc
    // {
    //     display: none;
    // }
}



.hdNavSrh {
    margin-left: auto;
    margin-right: 1rem;
    min-width: 450px;
}

.srh_v1 {
    background: #FBFBFB;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    padding: 2px 13px 2px 2px;
}

.srh_v1 .form-control
{
    border: 0px;
    background-color: transparent;
    text-align: center;
    font-family: var(--font-optima-medium);
    font-size: 20px;
    font-weight: normal;
    color: #000000;
}

.srh_v1 .form-control::placeholder
{
    color: #9D9D9D;
}

.srh_v1 .form-control:focus
{
    box-shadow: none;
}

.btnSrh {
    background: #F7F7F7;
    box-shadow: 4.125px 4.125px 8.25px rgba(206, 206, 206, 0.5), -4.125px -4.125px 8.25px #FFFFFF;
    border-radius: 50%;
    padding: 2px;
    text-align: center;
    line-height: 27px;
    min-width: 33px;
}
.btnSrh:focus {
    box-shadow: none;
}



.hdNavMenu ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.hdNavMenu ul li {
    display: inline-block;
    vertical-align: middle;
    padding: 7px 10px;
    margin: 0 10px;
}

.hdNavMenu ul li a
{
    color: #000000;
    font-family: var(--font-optima-bold);
    font-size: 16px;
    text-decoration: none;
}

.hdNavMenu ul li a.dropdown-toggle
{
    position: relative;
}

.hdNavMenu ul li a.dropdown-toggle::after
{
    display: none;
}


@media only screen and (min-width: 992px)
{
    .hdNavMenu ul li:hover .hdDdMn
    {
        display: block;
    }
    .hdNavMenu ul li .dropdown-menu.hdDdMn.show
    {
        display: none;
    }
}


.hdDdMn {
    background-image: linear-gradient(180deg, #FAFAFA 0%, rgba(255, 255, 255, 0.86) 100%);
    border: 0px;
    border-top: 1px solid #000000;
    border-radius: 0px;
    padding: 0px;
    min-width: 142px;
}

.hdDdMn .dropdown-item
{
    color: #000000;
    font-size: 14px;
    font-weight: normal;
    font-family: var(--font-optima-regular);
    padding: 12.5px 16px;
    border-bottom:  0.8px solid #C4C4C4;
}

.hdDdMn .dropdown-item:last-child
{
    border-bottom: 0px;
}

.btn-13433 {
    min-width: 134px;
    font-weight: initial;
    font-size: 15px;
    font-family: var(--font-optima-medium);
    border-radius: 30px;
    padding: 4.5px 12px;
}

.hdWalCnt
{
    padding: 0px 12px 0px 4px;
    /* temparory fix */
    @media only screen and (max-width: 575px)
    {
        padding: 0px 8px 0px 4px;
    }
}

.btn-dGrey {
    background-color: #474747;
    color: #ffffff;
}
.btn-dGrey:hover {
    background-color: #000000;
    color: #ffffff;
}


.btn-shd {
    box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5), 5px 5px 8px rgba(226, 226, 226, 0.25), 10px 10px 20px rgba(223, 223, 223, 0.5), -10px -10px 20px #FFFFFF;
}


.hdPrfIg .btn.dropdown-toggle
{
    padding: 0px;
}

.hdPrfIg .btn.dropdown-toggle::after
{
    display: none;
}

.hdPrfIg .btn:focus
{
    box-shadow: none;
}

.hdPrfIg .btn img
{
    width: 46px;
    height: 46px;
    border-radius: 50%;
    object-fit: cover;
}

.hdPrfIg .dropdown-menu {
    margin-top: 10px;
}


@media only screen and (min-width: 1200px)
{
    .btnSrh_v2 {
        display: none;
    }
}


@media only screen and (max-width: 1439px)
{
    .hdNavSrh
    {
        min-width: 370px;
    }
}
@media only screen and (max-width: 1300px)
{
    .hdNavSrh {
        min-width: 320px;
        margin-right: 0.5rem;
    }
}
@media only screen and (max-width: 1199px)
{
    .hdNavSrh .srh_v1 {
        display: none;
    }

    .hdNavSrh {
        min-width: auto;
    }

    .btnSrh_v2:focus {
        box-shadow: none;
    }
}



/* ----- Mobile Menu Toggle Start ----- */
.mobMenu {
    display: inline-block;
    vertical-align: middle;
}

.smClose {
    width: 40px;
    height: 40px;
    z-index: 9999999;
    overflow: hidden;
    margin-left: 12px;
}
#closeicon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transform: scale(2) translate(0px, -1px);
    position: relative;
}
#closeicon path {
    fill: none;
    -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -moz-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -o-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -ms-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 20px;
    stroke-linecap: round;
    stroke: #716C70;
    stroke-dashoffset: 0px;
}
#closeicon path#top,
#closeicon path#bottom {
    stroke-dasharray: 240px 950px;
}
#closeicon path#middle {
    stroke-dasharray: 240px 238px;
}

.smClose.active #closeicon  path#top,
.smClose.active  #closeicon path#bottom {
    stroke-dashoffset: -650px;
    stroke-dashoffset: -650px;
}
.smClose.active #closeicon  path#middle {
    stroke-dashoffset: -115px;
    stroke-dasharray: 1px 220px;
}
/* ----- Mobile  Menu Toggle End  ----- */


@media only screen and (max-width: 991px)
{
    .hdNavMenu {
        position: fixed;
        top: 0px;
        left: 0px;
        width:50%;
        min-width:250px;
        padding-top:15px;
        height: 100%;
        background-color: #474747;
       
        // padding: 2rem 0px 0px 0px;
        transform: translateX(-100%);
        transition: 0.3s;
        z-index: 999;

        &.mobileMenu{
            width:100%;
            padding-top:15px;
        }

        .dropdown-menu{
            background:rgba(0,0,0,0.5);
            border-top:0px;
            position:relative;
        }
        .dropdown{
            position:relative;
            .dropdown-toggle{
                img{
                    &.up{
                        filter:contrast(0);
                        height:10px;
                    }
                }
                display:flex;
                align-items: center;
                justify-content: center;

                span.linkTxt{
                    margin-left:auto;
                    margin-right:auto;
                }
            }

            &.open{
                .dropdown-toggle{
                    img{
                        &.up{
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }

    .sbMenu .hdNavMenu {
        transform: translateX(0%);
        box-shadow: 4px 0px 36px rgba(0,0,0,0.16);
    }

    .hdNavMenu ul li {
        display: block;
        padding:7px 0px;
        margin:0px 0px;
    }

    .hdNavMenu ul li a {
        width: 100%;
        display: inline-block;
        padding: 6px 15px;
        color:#fff!important;
        font-weight:600;
        border-bottom:2px solid rgba(255,255,255,0.5);
        text-align:center;
    }

    .hdNavMenu .hdDdMn {
        position: relative;
        width: 100%;
    }

    .indHd {
        padding: 0px 0px;
    }    
    .hdDdMn .dropdown-item{
        color:#fff;
        border-bottom:px solid rgba(255,255,255,0.5);
    }
    .mobMenu{
        .smClose{
            img{
                filter:contrast(0);
            }
        }
    }
    .logoAr{
        .logo-pc{
            width:200px;
        }
    }
    .hdNavbar{
        justify-content: space-between;
    }
}

@media only screen and (max-width: 575px)
{
    .logo-sm {
        max-width: 70px;
    }

    .logoAr {
        margin-right: 0.5rem;
    }

    .hdNavSrh {
        margin-left: auto;
        margin-right: 0;
    }

    .hdWalCnt .btn {
        font-size: 14px;
        min-width: auto;
    }
}
@media only screen and (max-width: 400px)
{
    .logo-sm {
        max-width: 52px;
    }

    .hdWalCnt .btn {
        font-size: 13px;
    }

    .hdWalCnt {
        padding: 0px 9px 0px 4px;
    }

    .smClose {
        margin-left: 6px;
    }
}
@media only screen and (max-width: 340px)
{
    .logo-sm {
        max-width: 46px;
    }

    .btnSrh_v2 {
        padding: 6px 6px;
    }

    .hdWalCnt .btn {
        font-size: 12px;
        padding: 6px 10px;
    }

    #closeicon {
        width: 30px;
        height: 30px;
        transform: scale(2) translate(1px, 0px);
    }

    .smClose {
        width: 30px;
        height: 30px;
    }

    .hdPrfIg .btn img {
        width: 34px;
        height: 34px;
    }
}
/*--||
Headeer Content End
||--*/



/*--||
Footer Content Start
||--*/

.container-v1 {
    max-width: 1370px;
}

.indFt {
    background-color: #F2F2F2;
    padding: 3rem 0px 1.75rem;
    // margin-top: 46px;
    
}

.ftCon h5 {
    color: #000000;
    font-size: 20px;
    font-family: var(--font-optima-bold);
    padding-bottom: 8px;
    font-weight: bold;
}

.ftMenu ul {
    padding: 0px;
    list-style: none;
}

.ftMenu ul li a {
    display: inline-block;
    color: #000000;
    font-size: 16px;
    font-family: var(--font-optima-regular);
    padding: 4.5px 0px;
    text-decoration: none;
}

.ftScl ul {
    list-style: none;
    padding: 0px;
}

.ftScl ul li {
    display: inline-block;
}

.ftScl ul li a {
    display: inline-block;
    background-color: #ffffff;
    border-radius: 10px;
    min-width: 54px;
    line-height: 54px;
    text-align: center;
    margin-right: 5px;
}


.subsIg .form-control {
    background-color: #FBFBFB;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px !important;
    border: 0px;
    text-align: center;
    font-size: 18px;
    color: #000000;
    margin-right: 10px;
    height: calc(1.5em + .75rem + 2px + 6px);
    max-width:400px;
}

.subsIg .form-control::placeholder
{
     color: #9D9D9D;       
}

.btn-16747 {
    min-width: 167px;
    font-size: 18px;
    font-family: var(--font-optima-bold);
    border-radius: 10px;
    padding: 9px 12px;
}

.btn-lGrey {
    background-color: #7C7C7C;
    box-shadow: -4.07317px -4.07317px 8.14634px rgba(255, 255, 255, 0.5), 4.07317px 4.07317px 6.51707px rgba(226, 226, 226, 0.25), 8.14634px 8.14634px 16.2927px rgba(223, 223, 223, 0.5), -8.14634px -8.14634px 16.2927px #FFFFFF;
    color: #ffffff;
}

.btn-lGrey:hover {
    background-color: #474747;
    color: #ffffff;
}


.ftCr {
    font-size: 16px;
    color: #000000;
    font-family: var(--font-optima-regular);
    padding: 0.5rem 0px 0px;
}


.indFtC,
.indFtInC
{
    margin: 0.5rem 0px;
}
@media only screen and (max-width: 1199px)
{
    .indFt {
        padding: 2rem 0px 1.75rem;
    }
}
@media only screen and (max-width: 991px)
{
    .indFtInr {
        display: flex;
        flex-direction: column-reverse;
    }

    .indFtInR {
        flex-direction: column-reverse;
    }
    
    .ftCon {
        text-align: center;
    }
    
    .ftLogo {
        text-align: center;
    }

    .ftCr {
        text-align: center;
    }

    .indFtC {
        margin: 1rem 0px;
    }

    .ftCon h5
    {
        padding-bottom: 0px;
    }
}
@media only screen and (max-width: 767px)
{
    .indFt {
        padding: 1rem 0px;
    }
    
    .ftMenu ul,
    .ftScl ul
    {
        margin: 0px;
    }
}
@media only screen and (max-width: 575px)
{
    .btn-16747 {
        font-size: 15px;
        min-width: 137px;
    }

    .subsIg .form-control {
        height: calc(1.5em + .75rem + 2px + 1.5px);
    }
}
@media only screen and (max-width: 400px)
{
    .ftCon h5 {
        font-size: 18px;
        padding-bottom: 2px;
        font-weight: bold;
    }

    .ftMenu ul li a {
        font-size: 15px;
    }
}
@media only screen and (max-width: 340px)
{
    .subsIg .form-control {
        margin-right: 6px;
    }

    .ftScl ul li a {
        min-width: 49px;
        line-height: 49px;
    }
}
/*--||
Footer Content End
||--*/



/*--||
Banner Content Start
||--*/
@media only screen and (min-width: 992px)
{
    .indBnrR {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
}


.indBnrCon
{
    max-width: 460px;
    margin-bottom: 48px;
}
.indBnrCon h2
{
    font-size: 35px;
    color: #474747;
    font-family: var(--font-optima-bold);
    padding-bottom: 1.25rem;
    font-weight: bold;

}

.indBnrCon p {
    color: #000000;
    font-size: 22px;
    line-height: 40px;
    font-family: var(--font-optima-regular);
}

.indBnrLks {
    padding: 1rem 0px 0px;
}

.indBnrLks .btn {
    margin: 0.5rem 0px;
}

.btn-20552 {
    min-width: 205px;
    font-size: 20px;
    font-family: var(--font-optima-medium);
    padding: 10px 12px;
    border-radius: 30px;
}




/*-- Banner Cards Start --*/
.bnrCdArtsR {
    margin-right: -32px;
    margin-left: -32px;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.bnrCdArtsC {
    padding-right: 32px;
    padding-left: 32px;
}

.bnrCdArtsC:nth-child(2) {
    margin-top: 5.125rem;
    padding-top: 5.125rem;
}

.bnrCdArtsC:nth-child(3) {
    margin-top: 10.125rem;
    padding-top: 10.125rem;
}

.bnrCdArtsCon {
    position: relative;
    text-align: center;
    padding-bottom: 5rem;
}

.bnrCdArtsIgTx {
    display: inline-block;
    background-color: #FFFFFF;
    border-radius: 20px;
    z-index: 9;
    position: relative;
    transition: 0.5s;
}

.bnrCdArtsCon:hover .bnrCdArtsIgTx 
{
    transform: matrix(1, 0.58, 0, 0.89, 0, 0)
    ;
}

.bnrCdArtsIg img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 8.8px;
}

.bnrCdArtsTx {
    padding: 12px 0px 6px;
}

.bnrCdArtsTx h6 {
    font-size: 18px;
    color: #000000;
    font-family: var(--font-roboto);
    font-weight: bold;
    margin: 0px;
}

.bnrCdArtsTx p {
    font-size: 15px;
    color: #000000;
    font-family: var(--font-roboto);
    margin: 0px;
}

.bnrCdArtsOL {
    position: absolute;
    bottom: -8px;
}

.bnrCdArtsOL img {
    transform: scale(2.07);
}

.indBnr {
    padding: 5rem 0px 5rem;
    margin-bottom: 3rem;
    overflow: hidden;
}
/*-- Banner Cards End --*/


@media only screen and (max-width: 1199px)
{
    .indBnrCon h2 {
      font-size: 28px;
        padding-bottom: 0.5rem;
    }

    .indBnrCon p {
        font-size: 20px;
    }

    .indBnrLks {
        padding: 0.5rem 0px 0px;
    }

    .bnrCdArtsR {
        margin-right: -24px;
        margin-left: -24px;
    }

    .bnrCdArtsC {
        padding-left: 24px;
        padding-right: 24px;
    }

    .bnrCdArtsOL img {
        transform: scale(1.875);
    }

    .bnrCdArtsCon {
        padding-bottom: 4rem;
    }

    .bnrCdArtsOL {
        bottom: -2px;
    }

    .bnrCdArtsC:nth-child(2) {
        margin-top: 4.125rem;
        padding-top: 4.125rem;
    }

    .bnrCdArtsC:nth-child(3) {
        margin-top: 8.125rem;
        padding-top: 8.125rem;
    }

    .indBnr {
        margin-bottom: 1rem;
    }
}
@media only screen and (max-width: 767px)
{
    .bnrCdArtsC:nth-child(3)
    {
        display: none;
    }

    .indBnrCon h2
    {
        font-size: 26px;
        padding-bottom: 0.25rem;
    }

    .indBnrCon p
    {
        font-size: 18px;
        line-height: 36px;
    }

    .indBnrCon
    {
        max-width: 100%;
    }

    .indBnrLks
    {
        padding: 0.25rem 0px 0px;
    }

    .indBnr {
        padding: 3rem 0px 3rem;
    }
}
@media only screen and (max-width: 575px)
{
    .bnrCdArtsC:nth-child(2)
    {
        display: none;
    }

    .bnrCdArtsR {
        margin-right: -15px;
        margin-left: -15px;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .bnrCdArtsC {
        padding-right: 15px;
        padding-left: 15px;
    }

    .indBnrCon p {
        font-size: 16px;
        line-height: 34px;
    }

    .btn-20552 {
        min-width: 175px;
        font-size: 18px;
        padding: 8px 12px;
    }

    .indBnrLks {
        padding: 0px;
    }
}
@media only screen and (max-width: 400px)
{
    .indBnrLks .col-auto {
        padding-right: 8px;
        padding-left: 8px;
    }

    .indBnrLks {
        margin: 0px -8px;
    }

    .btn-20552 {
        font-size: 16px;
        min-width: 155px;
    }

    .indBnr {
        padding: 3rem 0px 2rem;
    }
}
@media only screen and (max-width: 340px)
{
    .indBnrCon h2 {
        font-size: 24px;
        padding-bottom: 0px;
    }

    .indBnrCon p {
        font-size: 14px;
        line-height: 30px;
    }
}
/*--||
Banner Content End
||--*/



/*--||
Marketplace Content Start
||--*/
.mktPlc
{
    padding: 0.5rem 0px 1.625rem;
}

.secTl h2
{
    text-align: center;
    color: #000000;
    font-size: 32px;
    font-family: var(--font-optima-regular);
    font-weight: bold;
}

.mktPlcCon
{
    background-color: #FFFFFF;
    box-shadow: 0px 37.0703px 55.2673px rgba(0, 0, 0, 0.054);
    border-radius: 21.1476px;
}

.mktPlcCon > a
{
    display: inline-block;
    text-decoration: none;
    width: 100%;
}

.mktPlcIg img
{
    border-radius: 10px;
}

.mktPlcTx
{
    text-align: center;
    color: #000000;
    font-size: 25px;
    font-family: var(--font-optima-bold);
    padding: 10px 0px;
    font-weight: bold;
}

.mktPlcR
{
    padding: 2rem 0px;
}
@media only screen and (max-width: 1199px)
{
    .secTl h2 {
        font-size: 28px;
    }

    .mktPlcR {
        padding: 1rem 0px;
    }

    .mktPlcTx {
        font-size: 26px;
    }
}
@media only screen and (max-width: 767px)
{
    .secTl h2 {
        font-size: 26px;
    }

    .mktPlcR {
        padding: 0px 0px;
    }

    .mktPlcIg img {
        width: 100%;
    }

    .mktPlcTx {
        font-size: 24px;
    }
}
@media only screen and (max-width: 575px)
{
    .mktPlcTx {
        font-size: 20px;
    }
}
@media only screen and (max-width: 400px)
{
    .mktPlcTx {
        font-size: 18px;
    }
}
@media only screen and (max-width: 340px)
{
    .secTl h2 {
        font-size: 24px;
    }

    .mktPlcTx {
        font-size: 17px;
    }
}
/*--||
Marketplace Content End
||--*/


/*--||
Top Collections Content Start
||--*/
.tpClc {
    background-color: #FBFBFB;
    padding: 7.25rem 0px 5rem;
}

.tpClcTl {
    padding-bottom: 3.75rem;
}







.rnkClcR {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: flex-end;
    align-items: end;
}

.rnkClcCon
{
    position: relative;
    padding: 0px 12px;
}

.rnkClcC:nth-child(2) .rnkClcCon
{
    z-index: 9;
}

.rnkIgs {
    text-align: center;
    padding-bottom: 4rem;
    margin-bottom: 4rem;
    position: relative;
    z-index: 9;
}

.rnkClcC:nth-child(2) .rnkIgs
{
    padding-bottom: calc( 4.125rem + 20px);
    margin-bottom: calc( 4.125rem + 20px);
}

.rnkCrwIg {
    margin-bottom: 10px;
}

.rnkClcOl {
    position: absolute;
    top: 7.5rem;
    left: 50%;
    transform: translate(-50%, 50px);
    pointer-events: none;
}

.rnkClcC:nth-child(2) .rnkClcOl 
{
    transform: translate(-50%, 50px) rotateY(180deg);
}

.rnkClcOl img{
    transform: scale(2.75);
    transform-origin: center;
}

.rnkClcTx
{
    position: relative;
    z-index: 9;
}

.rnkClcNm {
    color: #000000;
    font-weight: bold;
    font-family: var(--font-optima-bold);
    font-size: 19px;
    text-align: center;
    padding: 4px 0px;
}

.rnkTxs {
    display: inline-block;
    vertical-align: middle;
}

.rnkTxs img {
    margin-right: 4px;
    vertical-align: middle;
}

.rnkTxs span {
    color: #000000;
    font-family: var(--font-optima-regular);
    font-size: 19px;
    vertical-align: middle;
}

.rnkChgs {
    display: inline-block;
    vertical-align: middle;
}

.rnkChgs i
{
    margin-right: 4px;
}

.oz-icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
}

.oz-icon-19 {
    width: 19px;
    height: 19px;
}

.oz-up-green
{
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.18673 0.511597L17.4247 14.7802H0.948738L9.18673 0.511597Z' fill='%231BE79D'/%3E%3C/svg%3E%0A");
}

.rnkChgs span {
    font-size: 18px;
    font-family: var(--font-optima-bold);
    display: inline-block;
    vertical-align: middle;
}

.rnkClcStsCrt {
    padding: 0px 18px;
}

.rnkClcStsChg {
    padding: 0px 18px;
}

.oz-down-red 
{
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.30741 15.0067L0.0694141 0.738091L16.5454 0.738091L8.30741 15.0067Z' fill='%23FF1D1D'/%3E%3C/svg%3E%0A");
}

.txGr {
    color: #1BE79D !important;
}

.txRd {
    color: #FF1D1D !important;
}






.tpClcRkLs {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 30px;
    padding: 28px 40px;
    margin: 6.875rem 0px -0.75rem;
}

.tpClcRkLsConR {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0px 1.5rem;
}

.rnkNtDt {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
}

.rnkNtNb {
    color: #000000;
    font-size: 25px;
    font-family: var(--font-optima-bold);
    min-width: 40px;
    text-align: right;
    @media only screen and (max-width: 575px)
    {
        min-width: 12px;
        margin-right: 8px;
    }
}

.rnkNtIg {
    margin: 0px 1.25rem;
    width: 95px !important;
}

.rnkNtIg img {
    //  width: 111 px !important;
     height: 100 px !important ;
     object-fit: cover;
}

.rnkNtDtTx {
    padding-left: 0.5rem;
}

.rnkNtDtNm {
    color: #000000;
    font-size: 20px;
    font-family: var(--font-optima-bold);
    padding: 5px 0px;
    margin-bottom: 5px;
    font-weight: bold;
}

.rnkNtDtAt {
    color: #000000;
    font-size: 18px;
    font-family: var(--font-optima-regular);
    padding: 5px 0px;
}

.tpClcRkLsConRt .rnkNtPrc {
    display: block;
    margin: 0px 0 20px 0px;
}

.tpClcRkLsConRt .rnkNtPrc span {
    padding-left: 10px;
}

.tpClcRkLsConRt .rnkNtChg {
    display: block;
}

.tpClcRkLsConRt .rnkNtChg span {
    padding-left: 10px;
}


.tpClcRkLsConLt,.tpClcRkLsConRt {
    padding: 10px 0px;
}


.tpClcLk {
    text-align: center;
    padding: 2rem 0px 1rem;
}
@media only screen and (max-width: 1300px)
{
    .tpClc
    {
        padding: 5.25rem 0px 0rem;
    }

    .tpClcRkLsConR {
        margin: 0px 1rem;
    }
}
@media only screen and (max-width: 1199px)
{
    .tpClc {
        padding: 4.25rem 0px 3rem;
    }

    .tpClcTl {
        padding-bottom: 2.75rem;
    }

    .rnkClcStsCrt {
        padding: 0px 10px;
    }

    .rnkClcStsChg {
        padding: 0px 10px;
    }

    .tpClcRkLs {
        margin: 4.875rem 0px 2.25rem;
        padding: 28px 30px;
    }

    .tpClcRkLsR
    {
        -ms-flex-pack: center;      
        justify-content: center;
    }
}
@media only screen and (max-width: 991px)
{
    .rnkClcOl img {
        transform: scale(1.25);
    }

    .rnkClcOl {
        top: 3.5rem;
    }

    .rnkClcTx {
        text-align: center;
    }

    .rnkClcCon {
        margin-bottom: 2rem;
    }

    .tpClc {
        padding: 3.25rem 0px 3rem;
    }

    .rnkClcC:nth-child(2) .rnkIgs
    {
        padding-bottom: calc( 4.125rem + 0px);
        margin-bottom: calc( 4.125rem + 0px);
    }
}
@media only screen and (max-width: 767px)
{
    .tpClcRkLs {
        margin: 2.875rem 0px 2.25rem;
        padding: 18px 20px;
        border-radius: 20px;
    }

    .tpClcRkLsConR 
    {
        margin: 0px;
    }

    .rnkIgs {
        margin-bottom: 2rem;
    }

    .rnkClcC:nth-child(2) .rnkIgs 
    {
        margin-bottom: 2rem;
    }
}
@media only screen and (max-width: 575px)
{

    .rnkClcCon {
        padding: 0px;
    }

    .rnkCrwIg {
        margin-bottom: 6px;
    }

    .rnkClcOl {
        width: 65%;
    }

    .tpClc {
        padding: 2.25rem 0px 2rem;
    }

    .tpClcTl {
        padding-bottom: 1.75rem;
    }

    .tpClcRkLsConR {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .tpClcRkLsConRt .rnkNtPrc {
        margin: 0px;
    }

    .tpClcRkLsConRt {
        display: -ms-inline-flexbox;
        display: inline-flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: flex-end;
        width: auto;
        padding: 10px 0px 10px 26px;
        padding-left: 10px;
        flex-direction:column;
    }

    .tpClcRkLs {
        margin: 1.5rem 0px;
        border-radius: 10px;
        padding: 15px 15px;
    }

    .rnkNtDtNm {
        font-size: 16px;
        padding: 2px 0px;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: bold;
    }

    .rnkNtDtAt {
        font-size: 18px;
        padding: 2px 0px;
    }
}
@media only screen and (max-width: 400px)
{
    .rnkNtDtNm {
        max-width: 114px;
    }
}
@media only screen and (max-width: 340px)
{
    .rnkClcOl {
        width: 90%;
    }


    .rnkNtNb {
        font-size: 22px;
        min-width: 26px;
    }

    .rnkNtIg {
        margin: 0px 0.75rem;
       
    }

    .rnkNtDtTx {
        padding-left: 0.25rem;
    }

    .rnkNtDtNm {
        font-size: 16px;
        margin-bottom: 2px;
    }

    .rnkNtDtAt {
        font-size: 16px;
    }

    .rnkTxs span {
        font-size: 17px;
    }

    .rnkChgs span {
        font-size: 16px;
    }

    .tpClcRkLsConRt .rnkNtChg span {
        padding-left: 4px;
    }

    .tpClcRkLsConRt .rnkNtPrc span {
        padding-left: 4px;
    }
}
/*--||
Top Collections Content End
||--*/


/*--||
Empty Div  Content Start
||--*/
.emptyDivH352 {
    padding: 11rem;
}
@media only screen and (max-width: 1300px)
{
    .emptyDivH352 {
        padding: 10%;
    }
}
/*--||
Empty Div  Content End
||--*/


/*--||
Mobile Search Content Start
||--*/
.mblSrh .modal-content
{
    background-color: transparent;
    border: 0px;
}

.mblSrh .modal-header
{
    border: 0px;
    padding: 0px 1rem;
}

.mblSrh  .close
{
    opacity: 1;
    text-shadow: none;
    color: #ffffff;
    font-family: var(--font-optima-regular);
    font-size: 18px;
}

.mblSrh .close:focus
{
    box-shadow: none;
    outline: 0px;
}
/*--||
Mobile Search Content End
||--*/





/*==============================||
    Drops Page Content Start
||==============================*/


/*--||
Latest Drops Content Start
||--*/
.ltstDrps {
    margin-bottom: 0rem;
    padding-bottom: 2rem;
}

.inrPgHdSpc {
    padding: 1.625rem;
}

.drpsTl
{
    // padding-bottom: 26px;
    padding-bottom: 9px;

}

.drpsTl h2 {
    text-align: left;
    margin-top: 3.5rem;
    margin-bottom: 1rem;
}

.grLn {
    background: linear-gradient(91.19deg, #F5E0FF 0%, #FCF4FF 0%, #FCF4FF 0.01%, #E7E3FF 28.11%, #D7F5FF 58.15%, #E8FFD7 89.17%);
    padding: 0.5rem;
}

.drpsCon {
    background-color: rgba(226, 226, 226, 0.25);
    // padding: 2.5rem 0px;
    overflow: hidden;
    position: relative;
}

.drpsConC
{
    margin: 1rem 0px;
}


.drpsConC-in {
    padding: 0px 10px;
}

.drpsConC-in > a {
    display: inline-block;
    width: 100%;
}

.drpsConIg {
    position: relative;
    border-radius: 15px;
}

.drpsConIg img {
    border-radius: inherit;
}

.drpsConTx {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #ffffff;
    font-family: var(--font-optima-medium);
    font-size: 18px;
    border-radius: inherit;
    opacity: 0;
    transition: 0.5s;
}

.drpsConIg:hover .drpsConTx  {
    opacity: 1;
}

.drpsConR {
    margin-left: -25px;
    margin-right: -25px;
}


.drpSldArw {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.drpSldArwLt {
    left: 60px;
}

.drpSldArwRt {
    right: 60px;
}

.btn-sldArw {
    background: rgba(255, 255, 255, 0.58);
    min-width: 56px;
    line-height: 54px;
    padding: 0px;
    border-radius: 50%;
}

.btn-sldArw:hover {
    background-color: #ffffff;
}

.btn-sldArw:focus {
    box-shadow: none;
}
@media only screen and (max-width: 1399px)
{
    .inrPgHdSpc
    {
        padding: 1rem;
    }
    .drpsCon {
        // padding: 2rem 0px;
    }

    .ltstDrps {
        padding-bottom: 0rem;
    }
}
@media only screen and (max-width: 1199px)
{
    .drpsTl {
        padding-bottom: 18px;
    }

    .drpSldArwLt {
        left: 40px;
    }

    .drpSldArwRt {
        right: 40px;
    }
}
@media only screen and (max-width: 991px)
{
    .inrPgHdSpc {
        padding: 0.75rem;
    }

    .drpsConC-in {
        padding: 0px 0px;
    }

    .drpsCon {
        padding: 1.5rem 0px;
    }

    .drpSldArwLt {
        left: 20px;
    }

    .drpSldArwRt {
        right: 20px;
    }
}
@media only screen and (max-width: 767px)
{
    .drpsConR {
        margin-left: 0px;
        margin-right: 0px;
    }
    .drpsConIg > img {
        width: 100% !important;
        height: 225px !important
    }

    .ltstDrps {
        padding-bottom: 0rem;
    }
}
@media only screen and (max-width: 575px)
{
    .drpsTl {
        padding-bottom: 12px;
    }
    .grLn
    {
        padding: 0.25rem;
    }

    .drpsCon {
        padding: 0.5rem 0px;
    }

    .btn-sldArw {
        min-width: 36px;
        line-height: 34px;
    }

    .btn-sldArw img {
        max-width: 10px;
    }

    .drpsConIg {
        border-radius: 10px;
    }

    .drpsConTx
    {
        font-size: 16px;
    }
}
@media only screen and (max-width: 400px)
{
    .drpsTl {
        padding-bottom: 8px;
    }

    .drpsCon {
        padding: 0.25rem 0px;
    }

    .drpsConTx,
    .drpsEveTm span
    {
        font-size: 14px;
    }
}
/*--||
Latest Drops Content End
||--*/


/*--||
Upcoming Drops Content Start
||--*/
.upcDrps {
    padding-bottom: 3.5rem;
    margin-bottom: 3.5rem;
}

.drpsEveTm {
    position: absolute;
    bottom: 0px;
    left: 15px;
    background: rgba(71, 71, 71, 0.9);
    width: 91%;
    text-align: center;
    padding: 9px 2px;
    border-radius: 0px 0px 15px 15px;
    transition: 0.5s;
    opacity: 1;
}
.drpsEveTm1 {
    position: absolute;
    //bottom: 0px;
    left: 15px;
    background: rgba(71, 71, 71, 0.9);
    width: 91%;
    text-align: center;
    padding: 9px 2px;
    border-radius: 0px 0px 15px 15px;
    transition: 0.5s;
    opacity: 1;
}

.drpsConIg:hover .drpsEveTm{
    opacity: 1;
}
.drpsEveTm1 img {
    display: inline-block;
    margin-right: 12px;
}

.drpsEveTm1 span {
    color: #FFFFFF;
    font-size: 20px;
    vertical-align: middle;
}

.drpsEveTm img {
    display: inline-block;
    margin-right: 12px;
}

.drpsEveTm span {
    color: #FFFFFF;
    font-size: 20px;
    vertical-align: middle;
}
@media only screen and (max-width: 1399px)
{
    .upcDrps
    {
        margin-bottom: 0px;
    }
}
@media only screen and (max-width: 1199px)
{
    .upcDrps
    {
        padding-bottom: 0rem;
    }
}
@media only screen and (max-width: 991px)
{
    .drpsEveTm span
    {
        font-size: 18px;
    }
}
@media only screen and (max-width: 575px)
{
    .drpsEveTm span {
        font-size: 12px;
    }

    .drpsEveTm img {
        max-width: 16px;
        margin-right: 6px;
    }
}
@media only screen and (max-width: 400px)
{
    .drpsEveTm span {
        font-size: 12px;
        width:80% !important;
    }
}
/*--||
Upcoming Drops Content End
||--*/



/*--||
Collection Drops Content Start
||--*/
.clcsDrps {
    // padding: 2rem 0px;
    // margin: 2rem 0px;
    padding: 0rem 0px !important;
     margin: -4rem 0 px !important ;
 }


.clcsDrpsCnt {
    background-color: rgba(226, 226, 226, 0.25);
    padding: 0rem 0px;
}

.clcsDrpsC {
    padding-top: 15px;
    padding-bottom: 15px;
}

.clcsDrpsCon {
    background-color: #FFFFFF;
    box-shadow: 0px 2px 3px #C4C4C4;
    border-radius: 25px;
    padding: 12px;

    &.ldtmCnt{
        padding-bottom:0px;
        .drpsEveTm{
            position:relative;
            left:-12px;
            width:calc(100% + 24px);
        }
    }
}

.clcsDrpsIg img {
    border-radius: 20px;
    width: 100%;
    object-fit: cover;
}

.clcsDrpsTx h5 {
    text-align: center;
    color: #000000;
    font-size: 20px;
    font-family: var(--font-optima-bold);
    padding: 2px 0px;
    font-weight: bold;

}

.clcsDrpsIg {
    margin-bottom: 1rem;
    position: relative;
}

.clcsDrpsIgOl {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.62));
    border-radius: 20px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 18px;
    font-family: var(--font-optima-bold);
    opacity: 0;
    transition: 0.3s;
}

.clcsDrpsCon:hover .clcsDrpsIgOl {
    opacity: 1;
}

.clcsDrpsTx {
    padding-bottom:0px;
}

.clcsDrpsLk
{
    display: block;    
}
.clcsDrpsLk:hover
{
    text-decoration: none;
}
@media only screen and (max-width: 991px)
{
    .clcsDrps {
        margin: 0rem 0px;
    }

    .clcsDrpsCnt {
        padding: 1rem 0px;
    }
}
@media only screen and (max-width: 575px)
{
    .clcsDrps {
        margin: 0rem 0px;
    }

    .UpcmngDrpsSec.clcsDrps .clcsDrpsTx {
        padding-bottom: 2.5rem;
    }

    .clcsDrpsTx h5
    {
        font-size: 18px;
    }
}
@media only screen and (max-width: 400px)
{
    .clcsDrpsTx h5 {
        font-size: 16px;
    }
}
/*--||
Collection Drops Content End
||--*/


/*==============================||
    Drops Page Content End
||==============================*/







/*==============================||
    NFT Mint Upcoming Page Content Start
||==============================*/


/*--||
Brand Details Content Start
||--*/
.mntBrndDt {
    padding: 2rem 0px 2.25rem 0px;
    // margin: 2rem 0px 2.25rem 0px;
    margin: 2rem 15px 2.25rem 15px;
    
    background: #fbfbfb;
}


.mntBrndCon h2 {
    font-size: 40px;
    font-family: var(--font-optima-bold);
    color: #000000;
    font-weight: bold;
}

.mntBrndTx {
    padding: 1.5rem 0px;
    min-height: 234px;
}

.mntBrndTx h5 {
    font-size: 20px;
    font-family: var(--font-optima-bold);
    color: #000000;
    font-weight: bold;
}

.mntBrndTx p {
    color: #000000;
    font-size: 18px;
    line-height: 30px;
    font-family: var(--font-optima-regular);
}

.mntBrndScl {
    max-width: 450px;
}

.mntBrndScl ul {
    padding: 0px;
    margin: 0px -8px;
    list-style: none;
}

.mntBrndScl ul li {
    display: inline-block;
    padding: 4px 7px;
}

.mntBrndScl ul li a {
    text-decoration: none;
    display: inline-block;
    background-image: linear-gradient(91.18deg, #FCF4FF 3.78%, #FCF4FF 3.79%, #F4F2FF 27.49%, #E6F9FF 59.57%, #F7FFF1 89.33%);
    border-radius: 10px;
    min-width: 212px;
}

.mntBrndScl ul li a:hover {
    background-image: linear-gradient(-91.18deg, #FCF4FF 3.78%, #FCF4FF 3.79%, #F4F2FF 27.49%, #E6F9FF 59.57%, #F7FFF1 89.33%);
}

.mntBrndSclIc {
    display: inline-block;
    background-color: #474747;
    border-radius: 10px;
    min-width: 54px;
    line-height: 54px;
    text-align: center;
}

.mntBrndSclIc img {
    filter: invert(1) brightness(20);
}

.mntBrndSclTx {
    font-size: 18px;
    font-family: var(--font-optima-bold);
    color: #474747;
    padding: 0px 8px 0px 8px;
}

.mntBrndIg {
    position: relative;
    display: inline-block;
}

.mntBrndIg img {
    width: 298px;
    height: 261px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.mntBrndIgOl {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.49), rgba(0, 0, 0, 0.49));
    border-radius: 50%;    
    color: #FFFFFF;
    font-size: 18px;
    font-family: var(--font-optima-bold);
    transition: 0.5s;
    opacity: 0;
}

.mntBrndIgOl:hover {
    opacity: 1;
}


.olFlexCenter
{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media only screen and (max-width: 1199px)
{
    .mntBrndDt
    {
        padding: 1rem 0px 1.25rem 0px;
    }
    .mntBrndTx
    {
        min-height: 194px;
    }
}
@media only screen and (max-width: 991px)
{
    .mntBrndDt {
        padding: 0rem 0px 0.25rem 0px;
    }


    .mntBrndC-rt {
        text-align: center;
        margin-bottom: 1rem;
    }

    .mntBrndCon h2 {
        font-size: 34px;
    }

    .mntBrndTx {
        min-height: 154px;
        padding: 1rem 0px;
    }

    .mntBrndCon {
        text-align: center;
    }

    .mntBrndScl {
        margin: 0 auto;
    }

    .mntBrndScl ul li {
        text-align: left;
    }
}
@media only screen and (max-width: 767px)
{
    .mntBrndCon h2 {
        font-size: 30px;
    }

    .mntBrndTx {
        min-height: 124px;
        padding: 0.5rem 0px;
    }
}
@media only screen and (max-width: 575px)
{
    .mntBrndTx
    {
        text-align: left;
    }

    .mntBrndCon h2 {
        font-size: 26px;
    }

    .mntBrndTx h5 {
        font-size: 18px;
    }

    .mntBrndTx p {
        font-size: 16px;
    }


    .mntBrndSclTx {
        font-size: 16px;
    }

    .mntBrndSclIc img {
        max-width: 20px;
    }

    .mntBrndSclIc {
        min-width: 44px;
        line-height: 44px;
        border-radius: 8px;
    }

    .mntBrndIgOl {
        font-size: 14px;
    }

    .mntBrndIg img {
        width: 248px;
        height: 248px;
    }

    .mntBrndScl ul {
        display: flex;
        flex-wrap: wrap;
    }
    
    .mntBrndScl ul li {
        flex: 0 0 50%;
    }

    .mntBrndScl ul li a
    {
        min-width: auto;
        width: 100%;
    }

    .mntBrndSclTx {
        font-size: 14px;
    }
}
@media only screen and (max-width: 400px)
{
    .mntBrndCon h2 {
        font-size: 24px;
    }

    .mntBrndSclTx {
        font-size: 13px;
    }

    .mntBrndSclIc {
        min-width: 35px;
        line-height: 40px;
    }
}
@media only screen and (max-width: 340px)
{   
    .mntBrndSclIc {
        min-width: 32px;
        line-height: 32px;
    }

    .mntBrndSclTx {
        font-size: 12px;
    }

    .mntBrndSclIc img {
        max-width: 18px;
    }
}
/*--||
Brand Details Content End
||--*/



/*--||
Mint Collection Details Content Start
||--*/
.mntClcDt {
    background-color: #474747;
    padding: 3.75rem 0px 2rem;
    overflow: hidden;
}

.mntClcDtCon {
    padding-bottom: 3rem;
    margin-bottom: 3rem;
}

.mntClcDtCon h2 {
    color: #FFFFFF;
    font-size: 32px;
    font-weight: bold;
    font-family: var(--font-optima-bold);
    text-align: center;
    padding-bottom: 22px;
}

.mntClcDtIgTx {
    background-color: #FFFFFF;
    box-shadow: 0px 1.55153px 2.32729px #C4C4C4;
    border-radius: 20px;
    padding: 8px;
}

.mntClcDtIg {
    position: relative;
    border-radius: 20px;
    display: inline-block;
}

.mntClcDtIg img
{
    border-radius: inherit;
}

.mntClcDtIgOl
{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.56), rgba(0, 0, 0, 0.56));
    border-radius: inherit;
    color: #FFFFFF;
    font-size: 18px;
    font-family: var(--font-optima-bold);
    opacity: 0;
    transition: 0.5s;
}

.mntClcDtIgOl:hover
{
    opacity: 1;
}

.mntClcDtTx {
    padding: 10px 0px 0px 10px;
}

.mntClcDtTx p {
    color: #000000;
    font-size: 18px;
    font-family: var(--font-optima-regular);
}

.mntClcDtAbt {
    display: inline-block;
    max-width: 350px;
    position: relative;
    padding: 0px 0px 6rem 2rem;
    margin-left: 14px;
}

.mntClcDtAbtBx {
    display: inline-block;
    background-color: #FFFFFF;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    min-width: 148px;
    text-align: center;
    margin: 4px 2px;
    padding: 14.5px 2px;
    position: relative;
    z-index: 9;
}

.mntClcDtAbtTl {
    color: #000000;
    font-size: 24px;
    font-family: var(--font-optima-bold);
    line-height: normal;
    padding: 8px 0px;
}

.mntClcDtAbtTx {
    font-size: 18px;
    color: #000000;
    font-family: var(--font-optima-regular);
    line-height: normal;
    padding: 8px 0px;
}

.mntClcDtAbtOl {
    position: absolute;
    bottom: 0px;
    left: 1rem;
}

.mntClcDtAbtOl img {
    transform: scale(1.125) translate(0px, 3rem);
}

.mntTmBx {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #3D3D3D;
    border-radius: 20px;
    position: relative;
    padding: 2rem 0px;
    margin-bottom: 5.75rem;
}

.mntTmTl {
    background: #262626;
    border-radius: 20px;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 244px;
    text-align: center;
    color: #FFFFFF;
    font-size: 30px;
    font-family: var(--font-optima-bold);
    padding: 3.125px 2rem;
    font-weight: bold;
}

.mntTmTx {
    text-align: center;
    font-size: 25px;
    color: #FFFFFF;
    padding: 12.375px 0px;
}

.mntTmTx img {
    margin-right: 1rem;
    vertical-align: middle;
}

.mntTmTx > * {
    display: inline-block;
    vertical-align: middle;
}


.mntUtlDt {
    margin-bottom: 2rem;
    padding-bottom: 10px;
}

.mntUtlDt h2 {
    text-align: center;
    font-family: var(--font-optima-bold);
    color: #ffffff;
    padding-bottom: 22px;
font-weight: bold;
}

.mntUtlDtCon {
    background-color: #313131;
    border-radius: 20px;
}

.mntUtlDtIg img {
    margin-top: 10px;
    width: 300px !important;
    height:300px !important;
    object-fit: cover;
    margin-top: 10px;
    width: 300px;
    height: 300px;
    background-color: white;

}

.mntUtlDtTx {
    padding: 2rem 2rem 2rem 2rem;
    min-height: 530px;
}

.mntUtlDtTx p {
    color: #FFFFFF;
    font-family: var(--font-optima-medium);
    font-size: 18px;
}

.mntClcLk {
    text-align: center;
    padding: 3rem 0px;
}

.btn-26150 {
    min-width: 261px;
    font-size: 20px;
    font-family: var;
    border-radius: 30px;
    padding: 8px 10px 10px 8px;
    font-family: var(--font-optima-bold);
}

.btn-white {
    background-color: #FFFFFF;
    color: #000000;
}

.btn-white:hover {
    background-color: #262626;
    color: #ffffff;
}

.btn-white:focus {
    box-shadow: none;
}

.btn-shd-v2 {
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
}
@media only screen and (max-width: 1199px)
{
    .mntClcDt {
        padding: 2.75rem 0px 1rem;
    }

    .mntClcDtCon h2 {
        font-size: 28px;
    }


    .mntClcDtIgTx {
        margin-bottom: 1.5rem;
    }

    .mntClcDtConC-rt {
        text-align: center;
    }

    .mntTmTx {
        font-size: 22px;
    }


    .mntTmTl {
        font-size: 28px;
    }

    .mntUtlDt h2 {
        font-size: 28px;
    }

    .mntUtlDt {
        margin-bottom: 1rem;
    }

    .mntClcLk {
        padding: 2rem 0px;
    }

    .mntClcDtAbt {
        margin-left: 0px;
        padding: 0px 2rem 6rem;
        max-width: 400px;
    }

    .mntClcDtAbtOl {
        left: 50%;
        transform: translateX(-50%);
    }

    .mntClcDtAbtOl img {
        transform: scale(2) translate(0px, 0.5rem);
    }
}
@media only screen and (max-width: 767px)
{
    .mntClcDtCon h2 {
        font-size: 26px;
    }

    .mntClcDtIgCnt {
        text-align: center;
    }

    .mntClcDtTx {
        text-align: center;
        padding: 1rem 0px 0;
    }

    .mntClcDtIgTx {
        border-radius: 15px;
        min-height: 240px;
    }

    .mntTmTl {
        font-size: 24px;
        white-space: nowrap;
    }

    .mntTmTx {
        font-size: 18px;
    }

    .mntTmBx {
        margin-bottom: 3.75rem;
    }

    .mntUtlDt h2 {
        font-size: 26px;
    }

    .mntClcLk {
        padding: 1.5rem 0px;
    }
}
@media only screen and (max-width: 480px)
{
    .mntClcDt {
        padding: 1.75rem 0px 1rem;
    }

    .mntClcDtCon h2 {
        font-size: 22px;
        padding-bottom: 15px;
    }

    .mntClcDtIgTx {
        border-radius: 12px;
    }

    .mntClcDtTx p {
        font-size: 16px;
    }

    .mntTmTl {
        font-size: 18px;
        min-width: auto;
    }

    .mntTmTx {
        font-size: 15px;
    }

    .mntTmTx img {
        margin-right: 0.25rem;
        max-width: 20px;
    }

    .mntTmBx {
        margin-bottom: 2.75rem;
        border-radius: 15px;
    }

    .mntUtlDt h2 {
        font-size: 22px;
        padding-bottom: 15px;
    }

    .mntUtlDtTx {
        padding: 1.5rem;
        min-height: 320px;
    }

    .mntUtlDtTx p {
        font-size: 16px;
    }

    .mntClcLk {
        padding: 1.25rem 0px;
    }

    .btn-26150 {
        font-size: 18px;
        min-width: 221px;
    }

    .mntClcDtAbt {
        margin-left: 0px;
        padding: 0px 1rem 6rem;
    }


    .mntClcDtAbtTl {
        font-size: 20px;
    }

    .mntClcDtAbtTx {
        font-size: 16px;
    }

    .mntClcDtAbtBx {
        padding: 13.5px 2px;
        min-width: 138px;
    }

    .mntClcDtAbtOl img {
        transform: scale(1.825) translate(0px, 0.125rem);
    }
}
@media only screen and (max-width: 400px)
{
    .mntClcDtCon h2 {
        font-size: 20px;
    }

    .mntClcDtIgTx {
        border-radius: 8px;
    }

    .mntTmTl {
        font-size: 16px;
    }

    .mntTmTx img {
        margin: 0px 4px 4px 4px;
    }

    .mntTmBx {
        border-radius: 12px;
    }

    .mntUtlDt h2 {
        font-size: 20px;
    }

    .mntUtlDtTx {
        padding: 1.25rem;
    }

    .mntUtlDtTx p {
        font-size: 14px;
    }

    .mntUtlDtCon {
        border-radius: 12px;
    }
}
@media only screen and (max-width: 340px)
{
    .mntClcDtAbtBx {
        min-width: 118px;
        padding: 10px 0px;
    }

    .mntClcDtAbtTl {
        font-size: 18px;
    }

    .mntClcDtAbtTx {
        font-size: 14px;
    }

    .mntClcDtAbtOl img {
        transform: scale(2) translate(0px, -13px);
    }

    .mntClcDtCon {
        margin-bottom: 1rem;
    }
}
/*--||
Mint Collection Details Content End
||--*/



/*--||
Mint Team Content Start
||--*/
.mntTeam {
    background-color: #FBFBFB;
    padding: -0.5rem 0px 4.875rem;
    margin-top: 6px;
    
}

.mntTeamTl {
    text-align: center;
    padding-bottom: 1rem;
}

.mntTeamTl h2 {
    color: #000000;
    font-size: 30px;
    font-family: var(--font-optima-bold);
    font-weight: bold;
}

.mntTeamC {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.mntTeamIg img {
    border-radius: 15px;
}

.mntTeamTx {
    padding: 2rem 0px;
}

.mntTeamTx h4 {
    color: #474747;
    font-size: 22px;
    font-family: var(--font-optima-bold);
}

.mntTeamTx p {
    font-size: 18px;
    color: #474747;
    font-family: var(--font-optima-regular);
    margin: 0px;
}

.mntTeamScl ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
}

.mntTeamScl ul li {
    display: inline-block;
    margin-right: 5px;
}

.mntTeamScl ul li a {
    display: inline-block;
    background-color: #EEEEEE;
    border-radius: 10px;
    min-width: 54px;
    line-height: 54px;
    text-align: center;
}

.mntTeamScl ul li a img {
    filter: contrast(0.375);
    transition: 0.5s;
}

.mntTeamScl ul li a:hover img {
    filter: contrast(1);
}
@media only screen and (max-width: 1199px)
{
    .mntTeam {
        padding: 2.5rem 0px 3.875rem;
    }

    .mntTeamTl h2 {
        font-size: 28px;
    }
}
@media only screen and (max-width: 767px)
{
    .mntTeam {
        padding: 2rem 0px 3rem;
    }

    .mntTeamTl h2 {
        font-size: 26px;
    }

    .mntTeamTl {
        padding-bottom: 0.5rem;
    }

    .mntTeamTx {
        padding: 1.25rem 0px;
    }

    .mntTeamTx h4 {
        font-size: 20px;
    }

    .mntTeamTx p {
        font-size: 16px;
    }
}
@media only screen and (max-width: 575px)
{
    .mntTeamTl {
        padding-bottom: 0.25rem;
    }

    .mntTeamTl h2 {
        font-size: 24px;
    }

    .mntTeamScl ul li a {
        min-width: 44px;
        line-height: 44px;
        border-radius: 6px;
    }

    .mntTeamScl ul li a img {
        max-width: 20px;
    }

    .mntTeam {
        // padding: 2rem 0px 2rem;
        padding: 0rem 0px 2rem;

    }
}
/*--||
Mint Team Content End
||--*/



/*--||
Mint Road Map Content Start
||--*/
.mntRdMp {
    // padding: 5rem 0px;
    padding: 0rem 0px;
    margin-top: -26px;
    

}

.mntRdMpTl {
    text-align: center;
    // padding: 0px 0px 22px;
    padding: 34px;
}

.mntRdMpTl h2 {
    color: #000000;
    font-size: 30px;
    font-family: var(--font-optima-bold);
    font-weight: bold;
    margin-top: 20px;    

}

.mntRdMpLns {
    position: relative;
    padding: 4.75rem 0rem 0;
    margin-bottom: 1.875rem;
}

.mntRdMpLns::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 50%;
    width: 3px;
    height: calc( 100% - 4px);
    background-image: linear-gradient(180deg, #D7F5FF 0%, #738489 47.92%, #000000 100%);
    border-radius: 12px;
    transform: translateX(-50%);
}

.mntRdMpLnR {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.mntRdMpLnC {
    padding: 4.75rem 1rem;
    -ms-flex: 1 1 0;
    flex: 1 1 0;
}

.mntRdMpLnR:last-child .mntRdMpLnC {
    padding-bottom: 0px;
}

.mntRdMpLnC-ct {
    margin: 0px 5.75rem;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    line-height: normal;
    font-size: 0px;
}

.mntRdMpLnDot {
    display: inline-block;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: #C6E2EB;
    position: relative;
}

.mntRdMpLnR:nth-child(2) .mntRdMpLnDot
{
    background-color: #b5cfd7;
}

.mntRdMpLnR:nth-child(3) .mntRdMpLnDot
{
    background-color: #a2b9c0;
}

.mntRdMpLnR:nth-child(4) .mntRdMpLnDot
{
    background-color: #8ea2a8;
}

.mntRdMpLnR:nth-child(5) .mntRdMpLnDot
{
    background-color: #7a8c91;
}

.mntRdMpLnR:nth-child(6) .mntRdMpLnDot
{
    background-color: #66757a;
}

.mntRdMpLnR:nth-child(7) .mntRdMpLnDot
{
    background-color: #546064;
}

.mntRdMpLnR:nth-child(8) .mntRdMpLnDot
{
    background-color: #404a4d;
}

.mntRdMpLnR:nth-child(9) .mntRdMpLnDot
{
    background-color: #2e3436;
}

.mntRdMpLnR:nth-child(10) .mntRdMpLnDot
{
    background-color: #181c1d;
}

.mntRdMpLnR:nth-child(11) .mntRdMpLnDot
{
    background-color: #020303;
}

.mntRdMpPrcTx {
    color: #000000;
    font-size: 24px;
    font-family: var(--font-optima-bold);
    text-align: right;
    line-height: normal;
}

.mntRdMpDtTx p {
    margin: 0px;
    color: #000000;
    font-size: 18px;
    font-family: var(--font-optima-regular);
    line-height: normal;
}
@media only screen and (max-width: 1199px)
{
    .mntRdMp {
        padding: 4rem 0px;
    }

    .mntRdMpTl h2 {
        font-size: 28px;
    }

    .mntRdMpLnC-ct {
        margin: 0px 4.75rem;
    }

    .mntRdMpLnC {
        padding: 3.75rem 1rem;
    }

    .mntRdMpLns {
        padding: 3.75rem 0rem 0;
    }
}
@media only screen and (max-width: 991px)
{
    .mntRdMp {
        padding: 3rem 0px;
    }

    .mntRdMpLns {
        padding: 2.75rem 0rem 0;
    }

    .mntRdMpLnC {
        padding: 2.75rem 1rem;
    }

    .mntRdMpLnC-ct {
        margin: 0px 3.75rem;
    }

    .mntRdMpPrcTx {
        font-size: 22px;
    }
}
@media only screen and (max-width: 767px)
{
    .mntRdMp {
        // padding: 2rem 0px;
        padding: 0rem 0px;

    }

    .mntRdMpTl h2 {
        font-size: 26px;
    }

    .mntRdMpLns {
        padding: 1.75rem 0rem 0;
    }

    .mntRdMpLnC {
        padding: 1.75rem 0.5rem;
    }

    .mntRdMpLnC-ct {
        margin: 0px 2.75rem;
    }

    .mntRdMpPrcTx {
        font-size: 20px;
    }
}
@media only screen and (max-width: 575px)
{
    .mntRdMpTl h2 {
        font-size: 24px;
    }

    .mntRdMpTl {
        padding: 0px 0px 16px;
    }

    .mntRdMpLns {
        padding: 1.5rem 0rem 0px;
    }

    .mntRdMpLnC {
        padding: 1.5rem 0.5rem;
    }

    .mntRdMpPrcTx {
        font-size: 18px;
    }

    .mntRdMpLnC-ct {
        margin: 0px 1.5rem;
    }

    .mntRdMpDtTx p {
        font-size: 16px;
    }

    .mntRdMpLnDot {
        width: 16px;
        height: 16px;
    }
}
@media only screen and (max-width: 340px)
{
    .mntRdMpLns {
        padding: 1rem 0px 0px;
    }

    .mntRdMpLnC {
        padding: 1rem  0.25rem;
    }

    .mntRdMpPrcTx {
        font-size: 16px;
    }

    .mntRdMpLnDot {
        width: 12px;
        height: 12px;
    }

    .mntRdMpDtTx p {
        font-size: 14px;
    }
}
/*--||
Mint Road Map Content End
||--*/



/*--||
Mint FAQ Content Start
||--*/
.mntFaq {
    background-color: #FBFBFB;
    // padding: 5rem 0px;
    padding: 0rem 0px;

}

.mntFaqTl {
    text-align: center;
    padding-bottom: 2rem;
    // margin-top: 71px;
    
}

.mntFaqTl h2 {
    color: #000000;
    font-size: 30px;
    font-family: var(--font-optima-bold);
    font-weight: bold;
    margin-top: 20px;    
}

.faqAcc {
    max-width: 840px;
    margin: 0 auto 0rem;
}

.faqAcc .card {
    background-color: #FFFFFF;
    box-shadow: 0px 1.55153px 2.32729px #C4C4C4;
    border-radius: 20px;
    border: 0px;
    margin-bottom: 15px;
}

.faqAcc .card .card-header {
    border-radius: inherit;
    cursor: pointer;
    background-color: transparent;
    border-bottom: 1px solid #C4C4C4;
    padding: 28px 36px 28px 36px;
}
.faqAcc .card:last-child .card-header{
    border-bottom:0px;
}

.faqAcc .card .card-header[aria-expanded="true"]
{
    border-radius: 0px;
}

.faqAcc [aria-expanded="true"] .accIcon-pl
{
    display: none;
}
.faqAcc [aria-expanded="true"] .accIcon-ms
{
    display: block;
}
.faqAcc [aria-expanded="false"] .accIcon-pl
{
    display: block;
}
.faqAcc [aria-expanded="false"] .accIcon-ms
{
    display: none;
}

.accText {
    -ms-flex-item-align: center;
    align-self: center;
    color: #000000;
    font-size: 24px;
    font-family: var(--font-optima-regular);
}

.faqAcc .card-body {
    padding: 28px 36px 28px 36px;
}

.faqAcc .card-body p {
    margin: 0px;
    color: #000000;
    font-size: 24px;
    font-family: var(--font-optima-regular);
}
@media only screen and (max-width: 1199px)
{
    .mntFaq {
      padding: 3rem 0px;
    }

    .mntFaqTl h2 {
        font-size: 28px;
    }

    .accText {
        font-size: 22px;
    }

    .faqAcc .card-body p
    {
        font-size: 22px;
    }

    .faqAcc
    {
        margin: 0 auto 3rem;
    }
}
@media only screen and (max-width: 991px)
{
    .faqAcc .card .card-header 
    {
        padding: 18px 26px 18px 26px;
    }

    .faqAcc .card {
        border-radius: 16px;
    }

    .accText {
        font-size: 20px;
    }

    .accIcon img {
        max-width: 30px;
    }

    .faqAcc .card-body {
        padding: 18px 26px 18px 26px;
    }

    .faqAcc .card-body p {
        font-size: 20px;
    }

    .faqAcc {
        margin: 0 auto 2rem;
    }
}
@media only screen and (max-width: 767px)
{
    .mntFaq {
        padding: 2.5rem 0px;
    }

    .mntFaqTl {
        padding-bottom: 1.5rem;
    }

    .mntFaqTl h2 {
        font-size: 26px;
    }
}
@media only screen and (max-width: 575px)
{
    .mntFaq {
        // padding: 2rem 0px;
        padding: 0rem 0px;

    }

    .mntFaqTl h2 {
        font-size: 24px;
    }

    .mntFaqTl {
        padding-bottom: 1.25rem;
    }

    .faqAcc .card .card-header
    {
        padding: 15px 20px;
    }

    .accText {
        font-size: 16px;
    }

    .accIcon img {
        max-width: 24px;
    }

    .faqAcc .card-body {
        padding: 15px 20px;
    }

    .faqAcc .card-body p {
        font-size: 16px;
    }

    .faqAcc {
        margin: 0 auto 1rem;
    }
}
@media only screen and (max-width: 340px)
{
    .mntFaq {
        padding: 1.5rem 0px;
    }

    .faqAcc .card {
        border-radius: 12px;
    }

    .faqAcc .card .card-header {
        padding: 15px 15px;
    }

    .faqAcc .card-body {
        padding: 15px 15px;
    }
}
/*--||
Mint FAQ Content End
||--*/


/*==============================||
    NFT Mint Upcoming Page Content End
||==============================*/





/*==============================||
    NFT Mint  Page Content End
||==============================*/


/*--||
Mint Button with input Content Start
||--*/
.hlBg {
    background-color: rgba(0, 0, 0, 0.3);
}

.mntTmIpSbm {
    max-width: 614px;
    margin: 0 auto;
}

.mntTmIp {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 0px;
}

.mntTmIpLbl {
    font-size: 25px;
    font-family: var(--font-optima-regular);
    color: #ffffff;
    padding: 1rem 0px;
}

.mntTmIpLbl span {
    font-size: 20px;
}



.ipPsMs {
    background: linear-gradient(91.18deg, rgba(252, 244, 255, 0.2) 3.78%, rgba(252, 244, 255, 0.2) 3.79%, rgba(244, 242, 255, 0.2) 27.49%, rgba(230, 249, 255, 0.2) 59.57%, rgba(247, 255, 241, 0.2) 89.33%);
    border-radius: 50px;
    max-width: 210px;
    padding: 0px 12px;
    -ms-flex-align: center;
    align-items: center;
}

.ipPsMs .form-control {
    background-color: transparent;
    border: 0px;
    font-size: 25px;
    color: #ffffff;
    text-align: center;
    padding: 6px 12px;
}

.ipPsMs .form-control:focus {
    box-shadow: none;
}

.ipPsMs .btn:focus {
    box-shadow: none;
}

.mntTmIpVal {
    margin: 1rem 0px;
}

.mntTmSbmR
{
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 1199px)
{
    .mntTmIpLbl {
        font-size: 22px;
    }
}
@media only screen and (max-width: 991px)
{
    .ipPsMs .form-control {
        font-size: 22px;
    }

    .mntTmIpLbl {
        font-size: 20px;
    }
}
@media only screen and (max-width: 767px)
{
    .mntTmIp {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .mntTmIpLbl {
        width: 100%;
        text-align: center;
    }

    .mntTmSbmR {
        -ms-flex-pack: center;
        justify-content: center;
    }

    .mntTmSbmR > div:first-child {
        margin-bottom: 1rem;
    }
}
@media only screen and (max-width: 575px)
{
    .mntTmIpLbl {
        font-size: 18px;
        padding: 0.5rem 0px;
    }

    .mntTmIpVal {
        margin: 0.5rem 0px;
    }

    .ipPsMs .form-control {
        font-size: 18px;
    }

    .ipPsMs .btn img {
        max-width: 18px;
    }
}
@media only screen and (max-width: 340px)
{
    .mntTmIpLbl {
        font-size: 16px;
    }

    .ipPsMs .form-control {
        font-size: 16px;
    }

    .ipPsMs .btn img {
        max-width: 16px;
    }
}
/*--||
Mint Button with input Content End
||--*/


/*==============================||
    NFT Mint  Page Content End
||==============================*/




/*==============================||
    Stats Ranking Page Content Start
||==============================*/


/*--||
Inner page banner Content Start
||--*/
.inrBnrTl
{
    text-align: center;
    padding-bottom: 1.875rem;
    color: #474747;
    font-size: 35px;
    font-weight: bold;
}

.inrBnrImg img
{
    width: 100%;
}
@media only screen and (max-width: 991px)
{
    .inrBnrTl {
        font-size: 28px;
        padding-bottom: 1rem;
    }
}
@media only screen and (max-width: 575px)
{
    .inrBnrTl {
        font-size: 26px;
        padding-bottom: 0.75rem;
    }
}
@media only screen and (max-width: 340px)
{
    .inrBnrTl {
        font-size: 24px;
    }
}
/*--||
Inner page banner Content End
||--*/



/*--||
Stats Ranks Content Start
||--*/
.stsRnk {
    padding: 1.875rem 0px;
}
/*--||
Stats Ranks Content End
||--*/



/*--||
Stats Filter Content Start
||--*/
.rnkFltCnt {
    padding: 1rem 0px;
    margin: 10px 0px 20px;
}

.rnkFlts {
    text-align: center;
}

.rnkFltDd {
    display: inline-block;
    margin: 1rem 1.125rem;
}

.rnkFltDd .btn {
    background-color: #FFFFFF;
    border: 1px solid #9F9F9F;
    border-radius: 10px;
    min-width: 265px;
    color: #000000;
    font-size: 20px;
    font-family: var(--font-optima-regular);
    position: relative;    
}

.rnkFltDd .btn[aria-expanded="true"]
{
    z-index: 9999;
}

.rnkFltDd .btn:focus {
    box-shadow: none;
}

.rnkFltDd .dropdown-toggle::after {
    border: 0px;
    width: 14px;
    height: 9px;
    vertical-align: middle;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.369584 0.0747521C0.10398 0.210828 -0.0442705 0.566292 0.0117611 0.932796C0.0355815 1.08861 0.410107 1.54521 3.36654 5.0229C6.32301 8.50054 6.71118 8.9411 6.84364 8.96912C7.22079 9.04892 7.00172 9.2779 10.619 5.0229C14.2352 0.769122 14.0431 1.0235 13.973 0.584255C13.9363 0.354015 13.6984 0.0741909 13.5027 0.031015C13.1308 -0.0510799 13.318 -0.244552 10.0249 3.62532L6.99253 7.1888L3.9803 3.64777C2.29323 1.66454 0.918845 0.0852306 0.856132 0.0578657C0.70637 -0.00757637 0.517477 -0.00102784 0.369584 0.0747521Z' fill='%23474747'/%3E%3C/svg%3E%0A");
    transition: 0.5s;
}

.rnkFltDd .dropdown-toggle[aria-expanded="true"]::after{
    transform: rotateX(180deg);
}

.rnkFltDd .dropdown-menu {
    width: 100%;
    background-color: rgba(247, 247, 247, 0.9);
    border-radius: 10px;
    padding: 0rem 12px 0px 12px;
    margin-top: 0rem;
    border: 0px;
}

.rnkFltDd .dropdown-menu[x-placement="bottom-start"]
{
    border-radius: 0px 0px 10px 10px;
    padding-top: 1rem;
    margin-top: -1rem;
}

.rnkFltDd .dropdown-menu[x-placement="top-start"]
{
    border-radius: 10px 10px 0px 0px;
    padding-bottom: 1rem;
    margin-bottom: -1rem;
}

.rnkFltDd .dropdown-item {
    font-size: 18px;
    text-align: center;
    color: #000000;
    padding: 13.5px 10px;
    font-family: var(--font-optima-regular);
    border-bottom: 1px solid #C4C4C4;
    background-repeat: no-repeat;
    background-position: calc(100% - 20px ) center;
    padding-right:30px;
}

.rnkFltDd .dropdown-item:active {
    background-color: #ffffff;
}

.rnkFltDd .dropdown-item:last-child {
    border-bottom: 0px;
}

.rnkFltDd .dropdown-item.selected {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.05396 1.02768C6.8497 1.2977 4.99446 2.21762 3.48618 3.78848C0.364003 7.04029 0.158056 12.1434 3.00664 15.6717C3.32878 16.0707 4.10087 16.8282 4.48286 17.12C7.22966 19.2187 10.8627 19.5894 13.9403 18.0852C14.8865 17.6227 15.5063 17.1858 16.275 16.4398C18.105 14.6634 19.1038 12.1692 18.9914 9.65572C18.8871 7.32248 17.9885 5.27138 16.3538 3.63567C14.9839 2.26494 13.3218 1.41248 11.3958 1.09284C10.883 1.00772 9.52521 0.969956 9.05396 1.02768ZM10.8459 1.74978C12.58 1.93909 14.2123 2.65947 15.5166 3.8111C17.4662 5.53265 18.5204 8.25502 18.2385 10.8404C17.933 13.6431 16.27 16.075 13.7827 17.3563C11.0788 18.7493 7.89596 18.558 5.35718 16.8499C3.34225 15.4942 2.02901 13.2998 1.76087 10.8404C1.47904 8.25502 2.53264 5.53401 4.48286 3.81088C6.22564 2.27102 8.59374 1.50396 10.8459 1.74978ZM14.8861 6.07977C14.8365 6.10667 13.2306 7.69258 11.3174 9.60403L7.8389 13.0794L6.49865 11.7442C5.76151 11.0099 5.11128 10.3948 5.05373 10.3775C4.78924 10.2979 4.531 10.5855 4.63702 10.8415C4.66588 10.9113 5.36317 11.6413 6.18651 12.4638C7.57355 13.8494 7.69493 13.9593 7.8389 13.9593C7.98482 13.9593 8.21855 13.735 11.6525 10.2999C13.6645 8.28724 15.3341 6.58384 15.3628 6.51458C15.43 6.35204 15.3529 6.15052 15.1961 6.07905C15.0593 6.01663 15.0021 6.01677 14.8861 6.07977Z' fill='black' stroke='black' stroke-width='0.3'/%3E%3C/svg%3E%0A");
}
@media only screen and (max-width: 575px)
{
    .rnkFltDd .btn
    {
        min-width: 245px;
        font-size: 18px;
    }
    .rnkFltDd .dropdown-item {
        font-size: 16px;
    }

    .rnkFltCnt
    {
        padding: 0px;
    }
}
/*--||
Stats Filter Content End
||--*/



/*--||
Stats Table Content Start
||--*/
.rnkTbl
{
    margin-bottom: 2rem;
}

.dbTbl
{
    overflow: auto;
}

.dbTbl table
{
    width: 100%;
}

.dbTbl table thead
{
    
    background-image: linear-gradient(91.18deg, #FCF4FF 3.78%, #FCF4FF 3.79%, #F4F2FF 27.49%, #E6F9FF 59.57%, #F7FFF1 89.33%);
    border-bottom: 1px solid #000000;
}

.dbTbl table thead th
{
    color: #000000;
    font-size: 20px;
    padding: 36px 20px 12px 20px;
    font-family: var(--font-optima-bold);
}

.dbTbl table tbody tr:not(:last-child)
{
   border-bottom: 1px solid #DFDFDF;
}

.dbTbl table tbody td
{
    padding: 14px 20px 14px 20px;
    color: #000000;
    font-size: 18px;
}


.oz-icon-8 {
    width: 8px;
    height: 8px;
}

.oz-up-black {
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 0L7.4641 6H0.535898L4 0Z' fill='black'/%3E%3C/svg%3E%0A");
}

.oz-down-black {
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 6L0.535898 0L7.4641 0L4 6Z' fill='black'/%3E%3C/svg%3E%0A");
}
@media only screen and (max-width: 1199px)
{
    .dbTbl table thead th {
        padding: 26px 20px 12px 20px;
        font-size: 18px;
        white-space: nowrap;
    }

    .dbTbl table tbody td {
        white-space: nowrap;
    }
}
@media only screen and (max-width: 575px)
{
    .dbTbl table thead th {
        font-size: 16px;
        padding: 20px 18px 12px 20px;
    }

    .dbTbl table  .rnkNtDtNm {
        font-size: 16px;
    }

    .dbTbl table .rnkNtDtAt {
        font-size: 18px;
    }

    .dbTbl table .rnkNtNb {
        font-size: 20px;
    }

    .dbTbl table .rnkTxs span {
        font-size: 15px;
    }

    .dbTbl table .rnkChgs span {
        font-size: 16px;
    }

    .dbTbl table tbody td {
        font-size: 16px;
    }
}
/*--||
Stats Table Content End
||--*/



/*==============================||
3.5 Waiting for transaction approval Page Content Start
||==============================*/

/*--||
Page Center Content Start
||--*/
.pgFlCnt {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: 99999;
}

.usrInfo {
    text-align: center;
    padding: 0 1rem;
}

.usrInfoIg {
    margin-bottom: 2.5rem;
}

.usrInfoTx {
    color: #474747;
    font-size: 30px;
    font-family: var(--font-optima-bold);
}

.rotation {
    -webkit-animation: 1s linear infinite spinner-border;
    animation: 1s linear infinite spinner-border;
    transform-origin: center;
}
@media only screen and (max-width: 991px)
{
    .usrInfoIg {
        margin-bottom: 1.5rem;
    }

    .usrInfoTx {
        font-size: 26px;
    }
}
@media only screen and (max-width: 575px)
{
    .usrInfoIg {
        margin-bottom: 1rem;
    }

    .usrInfoTx {
        font-size: 22px;
    }
}
@media only screen and (max-width: 340px)
{
    .usrInfoTx {
        font-size: 20px;
    }
}
/*--||
Page Center Content End
||--*/

/*==============================||
3.5 Waiting for transaction approval Page Content End
||==============================*/




/*==============================||
3.7 Payment Page Content Start
||==============================*/
.fnPymTl h2 {
    text-align: center;
    font-size: 35px;
    font-weight: bold;
    color: #000000;
    font-family: var(--font-optima-bold);
}

.fnPymTl {
    padding-bottom: 1.75rem;
}

.fnPymTx {
    min-height: 260px;
}

.fnPymTx p {
    text-align: center;
    color: #000000;
    font-size: 18px;
    line-height: 30px;
}

.fnPym {
    padding-bottom: 5rem;
}

.fnPymLk {
    text-align: center;
}

.btn-29852 {
    min-width: 298px;
    font-size: 20px;
    font-family: var(--font-optima-medium);
    border-radius: 52px;
    padding: 10px 12px;
}
@media only screen and (max-width: 991px)
{
    .fnPymTl h2 {
        font-size: 28px;
    }

    .fnPymTl {
        padding-bottom: 1rem;
    }

    .fnPymTx p {
        font-size: 17px;
    }

    .fnPymTx {
        min-height: 200px;
    }
}
@media only screen and (max-width: 575px)
{
    .fnPymTl {
        padding-bottom: 0.5rem;
    }

    .fnPymTl h2 {
        font-size: 24px;
    }

    .fnPymTx p {
        font-size: 15px;
        line-height: 28px;
    }

    .fnPymTx {
        min-height: 140px;
    }

    .btn-29852 {
        font-size: 18px;
        min-width: 248px;
    }
}
/*==============================||
3.7 Payment Page Content End
||==============================*/




/*==============================||
7 Profile Settings Page Content Start
||==============================*/
.prfStgTl {
    text-align: center;
    padding-bottom: 4rem;
}

.prfStgTl h2 {
    font-size: 35px;
    font-weight:bold;
    color: #000000;
}

.prfStgFrm {
    padding: 2rem 0px;
}

.prfStgFrm label {
    color: #000000;
    font-size: 20px;
    font-family: var(--font-optima-regular);
}

.prfStgFrm  .form-control
{
    border: 1px solid #000000;
    border-radius: 5px;
    height: calc(1.5em + .75rem + 2px + 2px);
    color: #000000;
}

.prfStgFrm  .form-control:focus
{
    box-shadow: none;
}

.prfStgFrm  .input-group {
    border: 1px solid #000000;
    border-radius: 5px;
    padding: 0px 4px;
}

.prfStgFrm .input-group .form-control
{
    border: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btnCp:focus {
    box-shadow: none;
}

.igIc {
    display: inline-block;
    margin: 0px 12px;
}

.igIc img {
    max-width: 23px;
}


.csFl
{
    height: auto;
}

.csFlCon
{
    position: relative;
    display: inline-block;
}

.csFlCon .custom-file-label::after 
{
    display: none;
}

.csFlCon label {
    margin: 0px;
    background-color: transparent;
    border: 0px;
    text-align: center;
    top: 50%;
    transform: translate(0px, -50%);
    cursor: pointer;
    height: auto;
}

.csFlCon label img {
    cursor: pointer;
}

.csFl .custom-file-input {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
}


.prfStgSbm {
    text-align: center;
    padding-bottom: 3rem;
}


.empty-160 {
    padding: 5%;
}

@media only screen and (max-width: 1199px)
{
    .prfStgTl {
        padding-bottom: 2rem;
    }

    .prfStgTl h2 {
        font-size: 28px;
    }
}
@media only screen and (max-width: 991px)
{
    .prfStgTl h2 {
        font-size: 26px;
    }

    .prfStgTl {
        padding-bottom: 1.5rem;
    }

    .prfStgFrm {
        padding: 1.125rem 0px;
    }

    .prfStgSbm {
        padding-bottom: 2rem;
    }
}
@media only screen and (max-width: 575px)
{
    .prfStgTl {
        padding: 0.5rem;
    }

    .prfStgTl h2 {
        font-size: 24px;
    }

    .prfStgFrm {
        padding: 0.5rem 0px;
    }

    .prfStgFrm label {
        font-size: 18px;
    }

    .prfStgSbm {
        padding-bottom: 1rem;
    }
}
@media only screen and (max-width: 340px)
{
    .prfStgFrm label {
        font-size: 16px;
    }

    .prfStgFrm .form-control {
        font-size: 14px;
    }
}
/*==============================||
7 Profile Settings Page Content End
||==============================*/



.MrktCollLstTit{
	position:relative;
    margin-top:-70px;
    
}
.McollIcon{
	position:absolute;
	top:0;
	left:50%;
	transform: translateX(-50%);
}
// .McollBgImg{
// 	padding-top:5rem;
// }
.CollDtTitl h4{
	font-size:35px;
	color: #000000;
    font-weight: bold;
}
.CollDtTitl p{
	font-size:20px;
	color: #000000;
}

.ItmLstBox{
	background: #FFFFFF;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	text-align:center;
	margin:6px 0;
	padding:7px;
}
.MrktCollLstTit h4{
	font-size: 24px;
	color:#000;
    font-weight: bold;
	font-family:var(--font-optima-bold);
}
.MrktIntro{
	padding:60px 0px 20px;
}
.MrktScrllIntrp{
	height:168px;
	overflow-y: auto;
	overflow-x: hidden;
}
/* Scrollbar Styling */
.MrktScrllIntrp::-webkit-scrollbar {
    width: 6px;
}
 
.MrktScrllIntrp::-webkit-scrollbar-track {
    background-color: #F4F4F4;
    -webkit-border-radius: 30px;
    border-radius: 30px;
}

.MrktScrllIntrp::-webkit-scrollbar-thumb {
    -webkit-border-radius: 30px;
    border-radius: 30px;
    background: #C4C4C4; 
}
.RctanglBg{
	background:url(/assets/images/market-place/rectangle-bg.png) no-repeat bottom;
	padding:0 3rem 11rem;
}
@media (max-width: 581px) {
	.RctanglBg{
		padding:0 0rem 10rem;
	}
	.MnftDetHdd .MrktHdd {
    	margin-bottom: 40px;
	}

}
@media(max-width:390px){
    .RctanglBg{
    background-size:contain;
    }
    }
.ItmLstBox h4{
	font-size: 24px;
	color:#000;
	font-family:var(--font-optima-bold);
}
.ItmLstBox p{
	font-size: 16px;
	color:#000;
}
.MrkItmLst{
	background:#fbfbfb;
	padding:40px 0;
	margin-top:-7rem;
}

.BxShdw{
	background: #FBFBFB;
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	padding:10px;
}
.SlectLWHg select{
	background: #fbfbfb;
    border: 1px solid #fbfbfb;
    outline: none;
    font-size:18px;
	color:#000;
}
.FiltHdd h4{
	font-size: 20px;
	font-family: var(--font-optima-bold);
	color:#000;
    font-weight: bold;
}
.FiltHddCLr a{
	font-size: 16px;
	color:#000;
	font-family: var(--font-optima-bold);
}
.FiltHddCLr a span{
	text-decoration:underline;
}
.SlectLWHg select{
	font-size:18px;
	color:#000;
}
.PrcDetHdd h4{
	font-size: 18px;
	color:#000;
	font-family: var(--font-optima-bold);
    font-weight: bold;
}
.PrcFrmToFrm .form-control{
	background: #F9F9F9;
	box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	width:55px;
	height:20px;
	font-size:12px;
	color:#000;
}
.PrcFrmToSlct select{
	background: #474747;
	border-radius: 5px;
	color:#fff;
}
.PrcFromTo{
	padding:25px 20px;
}
.ActvtyDet .form-group {
  display: block;
  margin-bottom: 12px;
}

.ActvtyDet .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.ActvtyDet .form-group label {
  position: relative;
    cursor: pointer;
    width: 15px;
    height: 15px;
    margin-bottom: 0;
    line-height: 0;
}

.ActvtyDet .form-group label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #000000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}
.ActvtyDet .form-group input:checked + label{
	background:#000;

}
.ActvtyDet .form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 1px;
  left: 5px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.ActvtyBrd{
	border-bottom:1px solid #000000;
}
.ActvtyPl2{
	padding-left:2.4rem;
}
.GrapTopSec{
	text-align:center;
}
.GrapAmnt{
	display:flex;
	justify-content: space-between;
}
.VolPrice h4{
	font-size:24px;
	font-family: var(--font-optima-bold);
	color:#000;
	margin-bottom:0;
}
.VolPrice p{
	font-size:18px;
	color:#000;
}
.TblActivty .table thead{
	background: linear-gradient(91.18deg, #FCF4FF 3.78%, #FCF4FF 3.79%, #F4F2FF 27.49%, #E6F9FF 59.57%, #F7FFF1 89.33%);
}
.TblActivty .table thead th{
	border: unset;
	font-size: 20px;
	color: #000000;
	font-weight:bold;
	border-bottom: 1px solid #000;
}
.TblActivty .table td{
	font-size: 17px;
	color: #000000;
    text-align:left;
	border-top:1px solid #DFDFDF;
    // padding:10px 8px;
	// min-width:130px;
}
.TblActivty .table td .Tblinr12R00{
	font-size: 12px;
	color:#000;
    
}
.GrapSlct select{
	background: #FBFBFB;
	border: 1px solid #9F9F9F;
	border-radius: 10px;
	font-size: 20px;
	color: #000;
	min-height:44px;
	width:100%;
	text-align: center;
}
.MrktHddLft p{
	font-size: 20px;
	color: #000000;
}
.MrktHddLft p a{
	font-size: 20px;
	color: #000000;
	text-decoration:underline;
}
.btn-26150{
	min-height:50px;
	min-width:261px;
	border-radius:30px;
}

.MrktItmLst{
	background: #FFFFFF;
	box-shadow: 0px 1.67038px 2.50558px #C4C4C4;
	border-radius: 20.8798px;
	padding:10px;
	position:relative	;
}
.MrktItm16B00 h4{
	font-weight: bold;
	font-size: 16px;
	color: #000000;
    // margin-right: 171px;
    white-space: nowrap;
}
.MrktItmPrsTx p{
	font-size: 16px;
	color:#000;
}
.MrktItmPrsTx p span{
	font-size: 12px;
	color:#000;
}
.ItmLstBoxImg{
	background: #F2F2F2;
	border-radius: 16.7038px;
	// min-height:210px;
	margin-bottom:20px;
	display: flex;
    align-items: center;
}
.MrkBuyBx{
	display:flex;
	background: linear-gradient(91.18deg, #FCF4FF 3.78%, #FCF4FF 3.79%, #F4F2FF 27.49%, #E6F9FF 59.57%, #F7FFF1 89.33%);
	box-shadow: 0px 5.33333px 5.33333px rgba(0, 0, 0, 0.25);
	justify-content:center;
	align-items:center;
	width:40px;
	height:40px;
	border-radius:50%;
}
.MrktLstBuy{
	position:absolute;
	top: 20px;
	left:20px;
}



/*Market place NFT details page css*/
.MrkNfdDtImg{
	position:relative;
}
.MndImg h4{
	font-size: 18px;
	font-family: var(--font-optima-bold);
	color:#000;
}
.MndImg{
	position:absolute;
	top:50%;
	left:50%;
	transform: translate(-50% , -50%);
}
.MrkNftDtPri{
	background:url(/assets/images/market-place/nft-det-price-bg.png) no-repeat center;
	padding:37px 45px 20px;
	background-size:cover;
}
.PrcNftdet h4{
	font-size: 23px;
	font-family: var(--font-optima-bold);
	color:#000;
    font-weight: bold;
}
.PrcNftdet p{
	font-size: 18px;
	color:#000;
}
.PrcNftdet p span{
	font-size: 23px;
	font-family: var(--font-optima-bold);
	color:#000;
	margin:0 20px 0 15px;
}
.btn-18452{
	min-height:52px;
	min-width:184px;
	border-radius:30px;
}
.MrkNftBg{
	background: #FBFBFB;
	padding:30px;
    margin-top: -33px;
    
}
.NftDetCllTrnHdd h4{
	font-size: 30px;
	color: #000000;
	font-family: var(--font-optima-bold);
	margin-bottom:40px;
    font-weight: bold;
}
.MrktNftDtSclLnk ul li a{
	background:#EEEEEE;
	border-radius:10px;
	padding:12px;
	width:54px;
	height:54px;
	display:flex;
	justify-content:center;
	align-items:center;
	margin-right:8px;
}
.MrktNftDtSclLnk ul li a:hover img{
	filter:brightness(0);
	transition:.4s all ease;
}
.MrktNftAbtBg{
	background: #FBFBFB;
	padding:35px;
}
.NftAbtImg{
	position:relative;
}
.NftAbtImgCnt{
	position:absolute;
	top:50%;
	left:50%;
	transform: translate(-50% , -50%);
}
.NftAbtImgCnt h4{
	font-size: 15px;
	color: #FFFFFF;
	font-family: var(--font-optima-bold);
}
.NftDetPrp .ItmLstBox h4{
	margin-bottom:10px;
}
.ItmLstBox p.Tx16R9d9d{
	font-size: 16px;
	color: #9D9D9D;
	margin-bottom:13px;
}
.TxUln{
	border-bottom: 1px  solid #9D9D9D;
    line-height: 1;
    margin-top:10px;
}
.TxUln1{
	border-bottom: 1px  solid #9D9D9D;
    line-height: 1;
    margin-top:10px;
}
.TxUln2
{
    line-height: 1;
    margin-top:10px;
    padding-top: 16px !important;
    padding-left: 19px!important;
}
.NftDtPrpHd h4{
	font-size: 20px;
	color: #000000;
	font-family: var(--font-optima-bold);
	margin-bottom:25px;
    font-weight: bold;
}
.Bgfbfb{
	background:#fbfbfb;
}
.NftDtPrp{
	padding:25px 25px 30px 30px;
}
.Tx18R000 p{
	font-size: 18px;
	color: #000000;
}
.Tx18B000 h4{
	font-size: 18px;
	color: #000000;
	font-family: var(--font-optima-bold);
}
.NftDtTbbHd .nav-tabs{
	background: linear-gradient(91.18deg, #FCF4FF 3.78%, #FCF4FF 3.79%, #F4F2FF 27.49%, #E6F9FF 59.57%, #F7FFF1 89.33%);
	border-bottom:unset;
	 padding:15px 40px 20px;   
	justify-content: space-between;
}
.NftDtTbbHd .nav-tabs .nav-item.show .nav-link, 
.NftDtTbbHd .nav-tabs .nav-link.active,
.NftDtTbbHd .nav-tabs .nav-link:focus, 
.NftDtTbbHd .nav-tabs .nav-link:hover{
	background: #474747;
	border-radius: 30px;
	font-size: 20px;
	color: #FFFFFF;
	font-family: var(--font-optima-bold);

}
.NftDtTbbHd .nav-tabs .nav-link{
	font-size: 20px;
	font-family: var(--font-optima-bold);
	color:#000;
	min-width:162px;
	min-height:40px;
	text-align:center;
	padding:4px 12px;
    font-weight: bold;
}
.TbbBdyBg{
	background:#fbfbfb;
	padding:40px 25px;
	min-height:630px;
}
.NftDtTbbTbl .TblActivty .table thead{
	background:transparent;
	
}
.NftDtTbbTbl .TblActivty .table thead th{border-bottom:1px solid #000;}
.NftDtTbbTbl .TblActivty .table td{
	background:#fff;
}

@media (min-width: 767px) {
  .ten-columns > .col-md-2 {
    flex: 0 0 33.3%;
    max-width: 33.3%;
    -ms-flex: 0 0 33.3%;
  }
}

@media (min-width: 991px) {
  .ten-columns > .col-md-2 {
    flex: 0 0 25%;
    max-width: 25%;
    -ms-flex: 0 0 25%;
  }

}
@media (min-width: 1199px) {
  .ten-columns > .col-md-2 {
    flex: 0 0 20%;
    max-width: 20%;
    -ms-flex: 0 0 20%;
  }
}

.BckToCollecBg{
	background: rgba(226, 226, 226, 0.25);
	padding:66px 0px 42px;
}
.BrdLnGrdnt{
	background:linear-gradient(91.19deg, #F5E0FF 0%, #FCF4FF 0%, #FCF4FF 0.01%, #E7E3FF 28.11%, #D7F5FF 58.15%, #E8FFD7 89.17%);
	padding:8px 0;
}
.BCollHdd h4{
	font-size: 30px;
	color: #000000;
	margin-bottom:35px;
    font-weight:bold;

}
.BckToCollec{
	padding-top:80px;
}
.NftDetCllTrn{
	padding:50px 0px 60px;
}
.MnftDetHdd{
	padding-top:55px;
}
.NftDtintro .MrktScrllIntrp{
	height:150px;
}
.MnftDetHdd .MrktHdd{
	margin-bottom:85px;
}


/*Sell page Css*/
.SpLstHImg{
	background:#FBFBFB;
	// min-height:277px;
	display:flex;
	align-items:center;
}
.SpLstHdd{
	display:flex;
	flex-wrap:wrap;
	align-items:center;
}
.SpLstCnt h4{
	font-size:21px;
	font-family: var(--font-optima-bold);
	color:#000;
    font-weight: bold;
}
.SpLstCnt p{
	font-size:20px;
	color:#000;
}
.SllPHdd h4{
	font-size: 21px;
	font-family: var(--font-optima-bold);
	color:#000;
    font-weight:bold;
}
.SpFeeCnt{
	display:flex;
	justify-content:space-between;
}
.SpFee{
	border: 1px solid #9F9F9F;
	border-radius: 5px;
	padding:20px 30px 10px;
}
.SellprcFrm span.form-control{
	background: #FBFBFB;
}
.SellprcFrm .form-control{
	border: 1px solid #9F9F9F;
	border-radius: 5px;
	min-height:40px;
	outline:none;
	box-shadow:unset;
}
.Tx20R7c7c{
	font-size:20px;
	color:#7c7c7c;
}


.sec-pad {
	padding: 50px 0;
}


/** Company - About Us Page **/

.about-sec h2,
.nftplatform-sec h2 {
	font-family: var(--font-optima-regular);
	font-style: normal;
	font-weight: bold;
	font-size: 35px;
	line-height: 40px;
	color: #474747;
}

.about-sec p {
	font-family: var(--font-optima-regular);
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 40px;
	text-align: justify;
	color: #000000;
}


/** NFT Platform Sec **/

.sec-head {
	padding-bottom: 20px;
}

.nftplatform-main {
	// background-image: url('/assets/images/company-profile/nft-bg.png');
    background-image: url('../assets/images/company-profile/nft-bg.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 50px 0;
}

.nftplatform-box {
	margin: auto;
	padding: 6px 0;
	// width: 270px;
}

.nftplatform-box button {
	background: #474747;
	border-radius: 15px;
	font-family: var(--font-optima-regular);
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 31px;
	text-align: center;
	color: #FFFFFF;
	border: 0.5px solid #FFFFFF;
	width: 100%;
	padding: 3px;
}

.nftplatform-box button:hover {
	background-color: #000000;
	color: #fff;
}

.nft-sub {
	background: #F7F7F7;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 23.2495px;
	height: 250.32px;
	margin: 15px 0;
}

.nft-para {
	font-family: var(--font-optima-regular);
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #000000;
	margin-bottom: 0;
}

.browseimg-div {
	padding-top: 23px;
	padding-bottom: 11px;
}


/** Join Us Sec Styles **/

.join-first,
.join-last {
	background-color: #494949;
}

.join-first {
	padding-left: 15px;
	padding-right: 15px;
}

.joinus-head button {
	background: #FFFFFF;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 15px;
	font-family: var(--font-optima-bold);
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 25px;
	text-align: center;
	color: #000000;
	width: 164px;
	height: 36px;
}

.join-first h2,
.join-first p {
	font-weight: normal;
	line-height: 40px;
	text-align: center;
	color: #FFFFFF;
	font-family: var(--font-optima-regular);
}

.join-first h2 {
	font-style: normal;
	font-size: 32px;
    font-weight: bold;
}

.join-first p {
	font-style: italic;
	font-size: 22px;
}

.joinus-inner {
	padding-top: 33px;
}

.join-last {
	padding: 25px 0 120px 0;
}

.grid-container {
	display: grid;
	grid-template-columns: auto auto auto auto auto;
	grid-column-gap: 15px;
}

.join-circle {
	margin: auto;
}


/** Empty Sec **/

.empty-sec {
	padding: 150px 0;
}


/** Contact Sec **/

.contact-sec {
	background-color: #474747;
	padding: 50px;
}

.contact-leftmain {
	padding-top: 50px;
}

.contact-sec h2 {
	font-family: var(--font-optima-regular);
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 40px;
	color: #DFDFDF;
}

.contact-left,
.contact-right {
	font-style: normal;
	font-size: 20px;
	line-height: 40px;
	color: #FFFFFF;
}

.contact-left {
	font-weight: bold;
	font-family: var(--font-optima-bold);
}

.contact-right {
	font-weight: normal;
	font-family: var(--font-optima-regular);
}

.contact-bg {
	background: linear-gradient(0deg, rgba(71, 71, 71, 0.51), rgba(71, 71, 71, 0.51)), url('../assets/images/company-profile/contact-bg.jpg');
	border-radius: 34px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 50px;
}

.contact-bg h2 {
	font-family: var(--font-optima-bold);
	font-style: italic;
	font-weight: bold;
	font-size: 25px;
	line-height: 40px;
	color: #FFFFFF;
}

.contact-bg label {
	font-family: var(--font-optima-regular);
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 40px;
	color: #FFFFFF;
}

.contact-bg input,
.contact-bg textarea {
	background: rgba(255, 255, 255, 0.4);
	border-radius: 5px;
	border: 0;
	outline: 0;
	box-shadow: none;
	padding: 10px 15px;
	width: 100%;
}

.contact-bg form {
	padding-top: 45px;
}

.submit-btn {
	background: #282828;
	border-radius: 32px;
	font-family: var(--font-optima-bold);
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 40px;
	text-align: center;
	color: #FFFFFF;
	width: 205px;
	padding: 5px 40px;
}

.submit-btn:hover {
	color: #fff;
}

.contact-detailsrow {
	padding-top: 56px;
}


/** Join Us Page Styles **/

.joinuspage-sec {
	// padding: 50px 0 800px 0;
    padding: 50px 0 71px 0;

}

.joinuspage-sec h2,
.joinuspage-sec p {
	text-align: center;
	color: #000000;
	font-weight: normal;
	font-family: var(--font-optima-regular);
}

.joinuspage-sec h2 {
	font-weight: bold;
	font-size: 35px;
	line-height: 40px;
}

.joinuspage-sec p {
	font-style: italic;
	font-size: 20px;
	line-height: 40px;
}

.joinuspage-sec h3 {
	font-family: var(--font-optima-bold);
	font-style: italic;
	font-weight: bold;
	font-size: 25px;
	line-height: 40px;
	color: #000000;
}

.joinuspage-sec label {
	font-family: var(--font-optima-regular);
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 40px;
	color: #000000;
}

.joinuspage-sec input,
.joinuspage-sec textarea {
	background: rgba(0, 0, 0, 0.07);
	border-radius: 5px;
	border: 0;
	outline: 0;
	box-shadow: none;
	padding: 5px 20px;
	width: 100%;
}

.joinuspage-sec input:focus {
	box-shadow: none;
	background: rgba(0, 0, 0, 0.07);
}

.joinuspage-sec textarea {
	resize: none;
}

.joinuspage-sec .send-btn {
	background: #474747;
	box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5), 5px 5px 8px rgba(226, 226, 226, 0.25), 10px 10px 20px rgba(223, 223, 223, 0.5), -10px -10px 20px #FFFFFF;
	border-radius: 32px;
	font-family: var(--font-optima-bold);
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 40px;
	text-align: center;
	color: #FFFFFF;
	width: 205px;
	height: 52px;
}

.joinuspage-sec form {
	padding-top: 30px;
}

.joinus-img {
	padding-top: 70px;
}


/** Wallet Page Styles START **/

.wallet-sec {
	padding: 50px 0 170px 0;
}

.wallet-sec h1,
.wallet-sec p {
	font-family: var(--font-optima-regular);
	font-weight: normal;
	line-height: 40px;
	text-align: center;
	color: #000000;
}

.wallet-sec h1 {
	font-style: bold;
	font-size: 35px;
}

.wallet-sec p {
	font-style: italic;
	font-size: 18px;
	margin-top: 25px;
}

.wallet-sec h3 {
	font-family: var(--font-optima-bold);
	font-style: normal;
	font-weight: bold;
	font-size: 15.4864px;
	line-height: 24px;
	text-align: center;
	color: #474747;
	margin-top: 20px;
}

.wallet-box {
	background: #F7F7F7;
	border-radius: 18.0025px;
	width: 170.35px;
	height: 157.19px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.wallet-main {
	padding-top: 100px;
}


/** Wallet Page Styles END **/


/** Profile Page Styles **/

.profile-sec {
	padding: 50px 0;
}

.profile-img {
	border: 5px solid #FFFFFF;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	margin: auto;
    background:#fff;
    overflow:hidden;
}

.user-detilas p {
	font-size: 30px;
}

.user-cont {
	background: #F2F2F2;
	border-radius: 10px;
	width: 200px;
	margin: auto;
	margin-top: 20px !important;
}

.user-details {
	margin-top: 15px;
}

.user-cont p {
	font-size: 18px;
}

.user-detilas p,
.user-cont p {
	font-family: var(--font-optima-regular);
	font-style: normal;
	font-weight: normal;
	line-height: 40px;
	text-align: center;
	color: #000000;
}

.user-cont a {
	padding-left: 20px;
}

.social-ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
}

.social-ul li {
	display: inline-block;
	margin: 0 12px;
}

.social-div {
	position: absolute;
	top: 100px;
	right: 25px;
}

.userprofile-main {
	margin-top: -75px;
}

.profile-container {
	padding-top: 75px;
}

.profiletab-main ul {
	background: linear-gradient(89.86deg, #DFDFDF 1.05%, #EBEBEB 45.89%, #F9F9F9 100%);
	border-radius: 30px;
	padding: 5px;
	justify-content: space-between;
}

.profiletab-main ul li .active {
	background-color: #474747 !important;
	border-radius: 30px;
	color: #fff !important;
}

.profiletab-main ul li {
	width: 50%;
}

.profiletab-main ul li a {
	font-family: var(--font-optima-bold);
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 49px;
	text-align: center;
	color: #000000;
	padding: 0;
}

.tabcontent-row {
	padding-top: 45px;
}

.search-box input {
	width: 100%;
	background: #FFFFFF;
	border: 1px solid #9F9F9F;
	border-radius: 30px;
	outline: 0;
	box-shadow: none;
	padding: 5px 15px;
	text-align: center;
}

.search-box input,
.search-box input::placeholder {
	font-family: var(--font-optima-regular);
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 40px;
	text-align: center;
	color: #474747;
}

.search-img {
	position: absolute;
	top: 12px;
	right: 30px;
}

.search-img button,
.profile-view button {
	background-color: transparent;
	border: 0;
	outline: 0;
}

.profiletab-main .dbTbl table thead th {
	padding: 15px;
}

.td-h2 {
	font-family: var(--font-optima-bold);
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 40px;
	color: #000000;
}

.price-td {
	font-family: var(--font-optima-regular);
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 40px;
	color: #000000;
}

.profile-tblrow {
	// padding: 60px 0 600px 0;
    padding: 60px 0 10px 0;

}


.profile-dropdown .rnkFltDd .btn {
	background: #FFFFFF;
	border: 1px solid #9F9F9F;
	border-radius: 30px;
	height: 50px;
}

.from-td {
	text-decoration: underline;
}


/** Profile Grid Styles **/

.grid-cont {
	margin-top: 12px;
}

.profile-gridbox {
	background: #FFFFFF;
	box-shadow: 0px 1.67038px 2.50558px #C4C4C4;
	border-radius: 20.8798px;
	padding: 10px;
	margin: 0 10px 25px 10px;
}

.grid-cont h4 {
	font-family: var(--font-optima-bold);
	font-weight: bold;
	font-size: 16.7038px;
}

.grid-cont p {
	font-family: var(--font-optima-regular);
	font-weight: normal;
	font-size: 13.3631px;
	margin-bottom: 0;
}

.grid-cont h4,
.grid-cont p .price-txt,
.price-amt {
	font-style: normal;
	line-height: 33px;
	color: #000000;
}

.price-txt {
	font-size: 11.6927px;
}

.price-amt {
	font-size: 15.0335px;
}

.grid-price {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.prfoile-grid {
	min-height: 221.33px;
	border-radius: 16.7px;
	position: relative;
	background: rgba(0, 0, 0, 0.2) !important;
	display: flex;
	align-items: center;
}

.playbtn-box {
	background: #474747;
	box-shadow: 4.34839px 4.34839px 8.69679px rgba(170, 170, 204, 0.5), -4.34839px -4.34839px 8.69679px #FFFFFF;
	border-radius: 27.8297px;
	width: 34.79px;
	height: 34.79px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 15px;
	right: 15px;
}

.profile-dropdown .rnkFltDd {
	margin: unset;
}


/** Transaction Styles **/

.transaction-volume h2,
.transaction-volume p {
	text-align: center;
	color: #000000;
	font-style: normal;
}

.transaction-volume h2 {
	font-family: var(--font-optima-bold);
	font-weight: bold;
	font-size: 24.5783px;
}

.transaction-volume p {
	font-family: var(--font-optima-regular);
	font-weight: normal;
	font-size: 19.6627px;
}

.transaction-total {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.transaction-total {
	margin-top: 50px;
}

.chart-row {
	margin-top: 30px;
}

.profiletransaction-tabcontent {
	margin-top: 80px;
}

.profiletransaction-tabcontent table td {
	vertical-align: middle;
}

.transaction-tabcontentmain {
	margin-top: 25px;
}

.transaction-totaldiv {
	padding-bottom: 0px;
}

.item-name {
	padding-right: 15px;
}


/*** Media Queries START ***/

@media only screen and (min-width: 1400px) {}

@media only screen and (min-width: 1200px) {}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .cir-brand {
        border-radius: 50%;
        width: 180px !important;
        height: 180px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .cir-brand {
        border-radius: 50% !important;
        width: 130px !important;
        height: 130px !important;
    }
        .contact-bg,
	.contact-sec {
		padding: 20px;
	}
	.social-ul li img {
		width: 100%;
		max-width: 20px;
	}
	.profile-img {
		width: 120px;
		height: 120px;
	}
	.userprofile {
		margin-top: -50px;
	}
	.prfoile-grid {
		min-height: 200px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.nftplatform-box {
		width: 100%;
	}
}

@media only screen and (min-width: 992px) {}

@media only screen and (min-width: 768px) {}

@media only screen and (min-width: 576px) and (max-width: 768px) {
	.prfoile-grid {
		min-height: 220px;
	}
}

@media only screen and (max-width: 1199px) {}

@media only screen and (max-width: 991px) {}

@media only screen and (max-width: 767px) {
	.nftplatform-box,
	.join-circle {
		margin-top: 25px;
	}
	.grid-container {
		grid-template-columns: auto auto;
	}
	.contact-detailsrow,
	.joinus-img {
		padding-top: 25px;
	}
	.contact-sec {
		padding: 20px;
	}
	.contact-bg {
		padding: 25px 15px;
	}
	.social-div {
		position: unset;
		padding-top: 25px;
	}
	.profile-img {
		width: 80px;
		height: 80px;
		;
	}
	.userprofile-main {
		margin-top: -40px;
	}
	.social-ul {
		justify-content: center;
	}
	.profile-view {
		margin-top: 25px;
	}
	.profile-dropdown .rnkFltDd {
		margin: unset;
		width: 100%;
		margin-top: 25px;
	}
	.profile-dropdown .rnkFltDd .btn {
		min-width: unset;
		width: 100%;
	}
}

@media only screen and (max-width: 576px) {
	.grid-container {
		grid-template-columns: auto;
	}
	.profiletransaction-tabcontent .rnkFltDd {
		margin: unset;
	}
}

// cms-page

.hd-prv-1 h4{
    font-size: 35px;
    font-weight: bold;   

}
.hd-prv-1 h6{
    font-size: 16px;
    font-weight: 400;   
    color: #595959;

}
.hd-prv-1 p{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 400;   
    color: #595959;

}
.privacncypolicy-nd-1 h5{
    font-size: 18px;
    line-height: 30px;
    // word-spacing: 6px;
    margin-bottom: 20px;
    color: #3f3f3f;
    text-align: justify;
    
}
.sub-head-1{
    font-size: 26px !important;
    font-weight: 700;  
    color: #000 !important; 

}
.privacncypolicy-nd-1 ul li{
    list-style-type: circle;

}
.TblActivty .table td.nowrap{
    white-space: nowrap;
}

.not-found-1{
    position: absolute;
    z-index: 999;
    left: 50%;
    transform: translate(-50px, 10px);
    font-size: 20px;
    margin-top: -8px;
    
}
.img-table-3{
    width: 100px;
}
.headerSearch{
    background: #474747;
    border-radius: 50%;
    width: 33px;
    height: 33px;
    display: flex!important;
    justify-content: center;
    align-items: center;
    // padding:2px;

    &.btn.btnSrh_v2{
        left:-2rem;
        top:7px;

        img{
            filter:brightness(0) invert(1);
            height:12px;
            max-width:unset;
        }
    }
}

.headerSearchBox{
    &.search-input-box{
        input{
            box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.1) inset;
            -webkit-box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.1) inset;
            -moz-box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.1) inset;
            border-color:transparent!important;
            height:40px;
        }    
    }
    
}
@media (min-width:900px) and (max-width: 1246px)
{
    .logoAr .logo-pc
    {
        width:150px;
    }
    .hdNavMenu ul li{
        padding:7px 5px;
        margin:0 3px;
    }
    .hdNavMenu ul li a{
        font-size:20px;
    }
    .indHd .hdNavSrh{
        margin-left:initial;
    }
    .indHd .hdNavMenu{
        margin-left:auto;
    }
}
@media (min-width:900px) and (max-width: 991px){
    .indHd .hdWalCnt{
        margin-left:auto;
    }
}
.hdNavSrh{display:none!important;flex-grow:1;}
.hdNavSrh.mobileSearch{display:flex!important;width:100%;justify-content:center;}
.hdNavSrh .headerSearchBox > div{width:100%;}
@media(min-width:768px){
    .hdNavSrh{display:flex!important;}
    .hdNavSrh.mobileSearch{display:none!important;}
}
@media(max-width:767px){
    .hdNavbar.navbar-expand{
        flex-wrap:wrap;
    }
    .hdNavbar .hdWalCnt{
        margin-left:auto;
    }
    .hdNavSrh.mobileSearch .search-input-box,.hdNavSrh.mobileSearch .search-input-box input{
        width:100% !important;
    }
    .hdNavSrh.mobileSearch .headerSearchBox > div{
        width:100%;
    }
}
// .logoAr .logo-pc
// {
//     // filter: grayscale(1);
//     max-width:137px
// }


// Style 26-05-2022

.mntClcDtIg img{
    width:280px;
    height:280px;
    object-fit: cover;
}
.headerSearchBox.search-input-box input{
    height:46px;
    background-color:#fbfbfb;
}
.headerSearch.btn.btnSrh_v2 {
    left: -2.5rem ;
      top: 13px;

}
.hdNavMenu ul li a{
    font-size:18px;
    font-weight:bold;
}
.mntBrndIg img{
    width: 250px;
    height: 250px;
    
}
@media (min-width:1700px){
.hdNavSrh{flex-grow: unset;width:760px;}
}

.cir-brand
{
    border-radius: 100px;
    width: 200px;
    height: 200px;
}
// .pro-imgs
// {
//     width: 100px;
//     height: 100px;
//     border-radius: 50%;
//     margin-top: 20px;
//     margin-left: 18px;
// }
.logfooter
{
    width: 160px;
  height: 160px; 
 }
.desc 
{
    padding-right: 274px ;
}
.intro
{
    padding-right: 226px;
    
}
@media(min-width:1200px){
    .hdNavMenu ul li a{
        font-size:20px;
        font-weight: bold;

    }
    .hdNavMenu ul li{
        padding: 7px 5px;
        margin: 0 3px;
    }
}
.borderRadius{
    border-radius:50%;
    object-fit:cover;
}

@media(max-width:991px){
    .rankingTable{
        table{
            thead{
                display:none;
            }
            tbody{
                tr{
                    td:nth-last-child(1),td:nth-last-child(2),td:nth-last-child(3),td:nth-last-child(4){
                        display:none;
                    }
                }
                td{
                    padding:5px 3px 5px 3px;

                    .rnkNtNb{
                        text-align:center;
                        font-weight:600;
                    }
                    .rnkNtDtNm{
                        font-weight:600;
                        width: calc(100% - 10px);
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .rnkClcStsChg{
                        padding:0px;
                    }
                    .rnkNtIg{
                        width:60px!important;
                        margin-right:5px!important;
                        img{
                            width:60px!important;
                            height:60px!important;
                            object-fit:cover;
                        }
                    }
                    .rnkTxs{
                        margin-bottom:10px;
                    }
                    .rnkNtDt{
                        width:100%;
                    }
                    .rnkNtDtTx{
                        flex:1;
                    }
                }
            }
        }
    }
    .profile-sec{
        padding-top:0px;
    }
    .profile-container{
        padding-top:35px;
    }
    .rnkNtIg{
        width:auto!important;
        margin:0 0.25rem;
        img{
            width:60px!important;
            height:60px!important;
        }
    }
}

@media(max-width:430px){
    .rankingTable{
        table{
            tbody{
                td{
                    .rnkNtDtTx{
                        max-width:100px;
                    }
                }
            }
        }
    }   
    .rnkNtIg{
        img{
            width:40px!important;
            height:40px!important;
            object-fit:cover;
        }
    } 
}

.status:after{
    content:'More';
    cursor: pointer;
}
.status.open:after{
    content:'Less';
}

.mobileCollapseTable{
    table{
        tbody{
            tr{
                td{
                    font-size:18px;
                    padding:0.5rem 3px;
                    vertical-align: middle;
                    p{
                        margin-bottom:0px;
                        // font-weight:bold;
                    }
                    img{
                        &.tblItemIcon{
                            width:50px;
                            height:50px;
                            object-fit:cover;
                        }
                    }

                    @media(max-width:565px){
                        font-size:14px!important;

                    }
                }
                &.collapseRow{
                    background:rgba(0,0,0,0.05);

                    td{
                        padding:0px;
                        border-bottom:0px;
                        border-top:0px;
                    }
                }

                @media only screen and (max-width: 575px)
                {
                    .tdClpTx p {
                        font-size: 15px;
                    }
                }               
                
            }
        }
    }
    .wt600{
        font-weight:600;
    }
    .greyTxt{
        color:#3D3D3D;
    }
    .ft-16{
        font-size:16px;
    }

    .ltGreyTxt
    {
        color: #707070;
    }
}
@media(min-width:992px){
    .hdNavMenu{
        // flex-grow:1;

        ul{
            display:flex;
            // justify-content:space-evenly;
        }
    }
    .mntFaq .mntFaqTl{padding-top:0.5rem;}
}
.prof
{
    font-weight: bold;
}

.faqAcc .card-body{
    border-top:1px solid #C4C4C4;
}
.faqAcc .card .card-header{
    border-bottom:1px solid transparent;
}
.mntTeam .mntTeamTl{padding-top: 2rem;}

.mntTeam{padding-bottom:0rem;}

.sell
{
    margin-left: 0rem!important;
    font-size: 18px;

}
.opacityBanner{
    opacity:0.2;
    position:absolute;
    left:0px;
    // top:0px;
    height:100%;
    width:100%;
    object-fit:cover;
}
@media(max-width:767px){
    .McollIcon{
        transform:translate(-50%,-50%);
    }
    .MrktHddLft p{
        font-size:16px;
    }
}
.modal{
    .listingModal{
        .modal-header{
            h3{
                &.title{
                    font-size:24px;
                    width:100%;
                    text-align:center;
                    font-weight:700;
                }
            }
        }
        .modal-content{
            background:url(../assets/images/modalBG.png)no-repeat center center;
            background-size:cover;

            .modal-body{
                padding-left:30px;
                padding-right:30px;
            }

            .listViewRow{
                width:100%;
                background: #FFFFFF;
                box-shadow: 0px 1.3px 2px #C4C4C4;
                border-radius: 16px;
                padding:25px;
                margin-bottom:20px;

                .countCnt{
                    width:25px;
                    height:25px;
                    border-radius:50%;
                    padding:5px;
                    background:#474747;
                    color:#fff;
                    font-size:18px;
                    font-weight:700;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                }

                h3{
                    color:#000;
                    font-size:16px;
                    font-weight:700;
                    white-space: nowrap;
                }
            }
        }
    }
}
@media(max-width:767px){
    .mntRdMp{margin-top:25px;}
    .clcsDrpsIg img {
        object-fit: contain;
    }
}
.drpsConImgCnt
{ 
     overflow:hidden;
}
.unswdImg
{ 
    max-width: unset;
    height: 263px !important;
    border-radius: 12px;
    width: 530px;
}
.drpsConImgInnerCnt
{ 
    position:relative; 
    transform:translateX(-50%);  
    left:50%;
}
.clcsDrps .clcsDrpsCon{
    position: relative;
}
.clcsDrps .clcsDrpsCon .drpsEveTm{
    left:0;
    width:100%;
}
.UpcmngDrpsSec.clcsDrps .clcsDrpsTx {
    padding-bottom:2.2rem;
}
.MorBtnMbl{
    display:none;
}
@media (max-width:1199px){
    .MorBtnMbl{
        display:block;
    }
    .MorBtnDsktp{
        display:none;
    }
}
@media (max-width:767px){
    .MrktCollLstTit{margin-top:-10px;}
}

@media (max-width:575px){
    .MorBtnMbl{
        display:block;
    }
    .MorBtnDsktp{
        display:none;
    }
    .TbbBdyBg{
        min-height:unset;
    }
}
@media (max-width:480px){
    .UpcmngDrpsSec.clcsDrps .clcsDrpsTx {
        padding-bottom: 3.2rem;
    }
}
.price-amt1{
    font-size:20px;
    font-weight: bold;
}
.wht-Bg
{
    background: #fff !important;
}
.wt600{
    font-weight:600;
}

@media (min-width:991px) and (max-width:1199px){
    .btn-26150{
        min-width:225px;
        margin-right: 678px;
    }

}


.drpsCon{
    
    .slick-list{
        padding:0px!important;
    }
}
.drpsConC{
    padding:0px!important;
}

@media(min-width:992px){
    .drpsCon{
        .slick-slide img{
            width:642px;
            height:321px;
            object-fit:cover;
        }
    }
}


.HdrSrchAuto{
    background:#fff;
    z-index:999;
    border-bottom:1px solid #ddd;
    padding:10px 20px;
}
.headerSearch.btn.btnSrh_v2{
    z-index:999; 
}
.hdNavSrh-Mobl{
    display:none !important;
}
@media (max-width:767px){
    .hdNavSrh-Mobl{
        display:flex !important;
    }
    .hdNavSrh-Mobl.hdNavSrh{
        flex-grow:unset;
    }
    .hdNavSrh-Mobl.hdNavSrh .btn.btnSrh_v2{
        position:unset;
    }
    .MblSearch .MblSerchSec{
        display: none;
    }
    .MblSearch1 .MblSerchSec{
        display: flex;
        position: absolute;
        left: 0;
        background: #fff;
        width: 100%;
        padding: 30px 30px 30px 45px;
        z-index: 9999;
        top: 0;
        // box-shadow: 0px -3px 6px 0px #0000001a inset;
        // -webkit-box-shadow: 0px -3px 6px 0px #0000001a inset;
        // -moz-box-shadow: 0px -3px 6px 0px #0000001a inset;
    }
    .MblAutoSrchInnpt input{
        width:100%;
        border:unset;
        outline:none;

    }
    .MblAutoSrchInnpt{
        display:grid;
        width:100%;
    }
    .SrchClsImg{
        position:absolute;
        left:15px;
        top: 50%;
        transform: translateY(-50%);
    }
    .SrchClsImg img{
        transform: rotate(90deg);
    }
    @media (max-width:361px){
        .logoAr .logo-pc{width:210px !important;}
    }

}
.SellprcFrm span.form-control {
    background: #FBFBFB;
    display: flex;
    align-items: center;
    justify-content: center;
}
.indHd{
    position:fixed;
    z-index: 9999;
    background:#fff;
    width:100%;
}
.MainCntnt {
    padding-top: 123px;
}
.MrkBuyBx{
    position: absolute;
    top:-15px;
    left:15px;
}
.ItmLstBoxImg{
    position: relative;
    margin-top:15px;
}
.CusPrfIcon{width:150px;}
@media (max-width:767px){
    .NftDtTbbHd .nav-tabs .nav-item.show .nav-link, 
    .NftDtTbbHd .nav-tabs .nav-link.active, 
    .NftDtTbbHd .nav-tabs .nav-link:focus, 
    .NftDtTbbHd .nav-tabs .nav-link:hover{
        font-size:13px;
        line-height:28px;
    }
    .NftDtTbbHd .nav-tabs .nav-link{
        min-width:100%;
        font-size:15px;
        line-height:28px;
    }
    .NftDtTbbHd .nav-tabs{
        justify-content:center;
        padding:15px 12px 20px;
    }
    .mntTmTx{padding:11.375px 15px;}
}

.RctanglSell .MrktItmLst{
    max-width:240px;
    margin:0 auto
}
.RctanglSell .rnkClcOl img {
    transform: scale(2);
}
.RctanglSell .rnkClcOl{
    top:auto;
    bottom:2rem;
}
@media (min-width:992px) and (max-width:1199px){
    .RctanglSell .rnkClcOl img {
        transform: scale(2.25);
    }
}
@media (max-width:991px){
    .RctanglSell .rnkClcOl img {
        transform: scale(1);
    }
    .RctanglSell .rnkClcOl{bottom:-2rem;}
}
@media (max-width:768px){
    .RctanglSell .rnkClcOl img {
        transform: scale(1.2);
    }
    .RctanglSell .rnkClcOl{bottom:0rem;}
}
.TblActivty .table td{
    white-space:nowrap;
}
.TblActivty .table td span.TxtEllps{
    width:100px;
    margin-right: 30px;
}

.headerSearchBox > div > div {

    position: absolute !important;
    max-height: 300px !important;
    left: 0 !important;
    top: 50px !important;
    border-radius: 10px !important;
    min-width: 100% !important;
    padding: 0px 0px !important;

}

.page_loader{
    top : 0;
    height: 100%;
    width: 100%;
    background-color: #ffffffcf;
    color: black;
    position: absolute;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;
}
    .TblActivty .table td span.TxtEllps{
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        width: 105px !important;
        display: inline-block !important;
        vertical-align: middle !important;
        white-space: nowrap !important;
    }
    .propic
    {
        width:140px;
        height:140px;
    }
    .slick-slide img {
        margin: auto;
        width: 50%;
        height: 50%;
        min-height: 115px !important;

    }

    .modal-content {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 1 px solid rgba(0,0,0,.2);
        border-radius: 0.3 rem ;
        outline: 0;
        z-index: 999 !important;
        margin-top: 129px  !important;
    }

    @media only screen and (max-width: 575px)
    {
        .mntBrndSclTx, .mntBrndTx p {
            font-size: 10px !important;
        }
    }

     @media (max-width : 450px){
        .TxtEllps{
            text-overflow: ellipsis !important;
            overflow: hidden !important;
            width: 122px !important;
            display: inline-block !important;
            vertical-align: middle !important;
            white-space: nowrap !important;
        }
    }

    @media (min-width : 450px) and (max-width : 768px){
        .TxtEllps{
            text-overflow: ellipsis !important;
            overflow: hidden !important;
            width: 130px !important;
            display: inline-block !important;
            vertical-align: middle !important;
            white-space: nowrap !important;
        }
    }

    @media (min-width : 768px) and (max-width : 992px){
        .TxtEllps{
            text-overflow: ellipsis !important;
            overflow: hidden !important;
            width: 190px !important;
            display: inline-block !important;
            vertical-align: middle !important;
            white-space: nowrap !important;
        }
    }

    @media (min-width : 992px) and (max-width : 1400px){
        .TxtEllps{
            text-overflow: ellipsis !important;
            overflow: hidden !important;
            width: 200px !important;
            display: inline-block !important;
            vertical-align: middle !important;
            white-space: nowrap !important;
        }
    }
    //////////////////////////////////
    

    .bbxTimerCnt{
        text-align:left;
        h3{
            font-size: 14px;
            text-align: left;
            color: #fff;
            margin-bottom: 0px;
            font-weight: 600;
        }
        span{
            font-size:12px;
        }
    }
    // .timerTx{
    //     margin-bottom:2.2rem;
    // }


    